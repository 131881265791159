import { ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * Get Fund
 */

export type FundInfoDataType = {
	readonly fundName: string;
	readonly fundType: string;
	readonly jurisdiction: string;
	readonly accountName: string;
	readonly accountNumber: string;
	readonly bsb: string;
	readonly abn: string;
	readonly tfn: string;
	readonly inClass: boolean;
};

export type MembersTrusteeInfoDataType = {
	readonly heading: string;
	readonly data: ObjectType;
};

export type CompanyDetailsDataType = {
	readonly companyName: string;
	readonly acn: string;
};

export type BasicFundInfoDataType = {
	readonly fundType: string;
	readonly fundOrigin: string;
};

export interface FundType {
	readonly fundInfoData: FundInfoDataType;
	readonly membersTrusteesInfoData: Array<MembersTrusteeInfoDataType>;
	readonly companyDetailsData: CompanyDetailsDataType;
	readonly basicFundInfoData: BasicFundInfoDataType;
}

export type GetFundType = {
	readonly data: FundType;
	readonly isGettingFund: boolean;
	readonly isGetFundSuccess: boolean;
	readonly isGetFundError: boolean;
};

export const getFundDefaultValue: GetFundType = {
	data: {
		fundInfoData: {
			fundName: "",
			fundType: "",
			jurisdiction: "",
			accountName: "",
			accountNumber: "",
			bsb: "",
			abn: "",
			tfn: "",
			inClass: false,
		},
		membersTrusteesInfoData: [],
		companyDetailsData: {
			companyName: "",
			acn: "",
		},
		basicFundInfoData: {
			fundType: "",
			fundOrigin: "",
		},
	},
	isGettingFund: false,
	isGetFundSuccess: false,
	isGetFundError: false,
};

type GetFundAction = {
	type: typeof actionTypes.GET_FUND;
};

type GetFundSuccessAction = {
	type: typeof actionTypes.GET_FUND_SUCCESS;
};

type GetFundFailureAction = {
	type: typeof actionTypes.GET_FUND_FAILURE;
};

type GetFundResetAction = {
	type: typeof actionTypes.GET_FUND_RESET;
};

type GetBasicFundResetAction = {
	type: typeof actionTypes.GET_BASIC_FUND;
};

export type GetFundActionTypes =
	| GetFundAction
	| GetFundSuccessAction
	| GetFundFailureAction
	| GetFundResetAction
	| GetBasicFundResetAction;
