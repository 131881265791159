import React from "react";
import { RoutesType } from "__utils/types";

const Dashboard = React.lazy(() => import("__pages/Dashboard"));
const Account = React.lazy(() => import("__pages/Account"));
const Fund = React.lazy(() => import("__pages/Fund"));
const Documents = React.lazy(() => import("__pages/Documents"));
const Billing = React.lazy(() => import("__pages/Billing"));
const Requests = React.lazy(() => import("__pages/Requests"));
const RequestDetail = React.lazy(() => import("__pages/Requests/DocumentRequests/DocumentRequestDetail"));

export const routes: RoutesType = [
	{
		key: "0",
		path: ["/"],
		exact: true,
		component: Dashboard,
	},
	{
		key: "1",
		path: "/account",
		component: Account,
	},
	{
		key: "2",
		path: "/myfund",
		component: Fund,
	},
	{
		key: "3",
		path: "/documents",
		component: Documents,
	},
	{
		key: "4",
		path: "/billing",
		component: Billing,
	},
	{
		key: "5",
		path: "/requests",
		component: Requests,
	},
	{
		key: "6",
		path: "/request/:id",
		component: RequestDetail,
	},
];

export default routes;
