import * as actionTypes from "./actionTypes";
import { GetNotificationActionTypes, SendReferralActionTypes, SendReferralPayloadType } from "./types";

// Get NOTIFICATION
export const getNotificationCtr = (): GetNotificationActionTypes => {
	return {
		type: actionTypes.GET_NOTIFICATION,
	};
};

export const getNotificationResetCtr = (): GetNotificationActionTypes => {
	return {
		type: actionTypes.GET_NOTIFICATION_RESET,
	};
};

// Send Referral
export const sendReferralCtr = (payload: SendReferralPayloadType): SendReferralActionTypes => {
	return {
		type: actionTypes.SEND_REFERRAL,
		payload,
	};
};

export const sendReferralResetCtr = (): SendReferralActionTypes => {
	return {
		type: actionTypes.SEND_REFERRAL_RESET,
	};
};
