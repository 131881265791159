import BillingIcon from "__assets/svg/billing.svg";
import DashIcon from "__assets/svg/dash.svg";
import DocumentIcon from "__assets/svg/documents.svg";
import TradingIcon from "__assets/svg/trading.svg";
import MyFundIcon from "__assets/svg/wallet.svg";
import { endpoints } from "__utils/constants";
import { MenuItemsType } from "__utils/types";

const menuItems: Array<MenuItemsType> = [
	{
		menuIcon: DashIcon,
		menuText: "DASHBOARD",
		isExternal: false,
		openInNewTab: false,
		route: "/",
		tourId: "dashboard_target",
	},
	{
		menuText: "REQUESTS",
		isExternal: false,
		openInNewTab: false,
		route: "/requests",
		tourId: "requests_target",
	},
	{
		menuIcon: MyFundIcon,
		menuText: "MY FUND",
		isExternal: false,
		openInNewTab: false,
		route: "/myfund",
		tourId: "myfund_target",
	},
	{
		menuIcon: TradingIcon,
		menuText: "TRADING",
		isExternal: true,
		openInNewTab: true,
		route: endpoints.tradingExternalURL,
		tourId: "trading_target",
	},
	{
		menuIcon: DocumentIcon,
		menuText: "DOCUMENTS",
		isExternal: false,
		openInNewTab: false,
		route: "/documents",
		tourId: "documents_target",
	},
	{
		menuIcon: BillingIcon,
		menuText: "BILLING",
		isExternal: false,
		openInNewTab: false,
		route: "/billing",
		tourId: "billing_target",
	},
];

export default menuItems;
