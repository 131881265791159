import { LoadingOutlined } from "@ant-design/icons";
import cx from "classnames";
import React from "react";
import "./style.scss";

type Props = {
	size?: string;
	type?: string;
	onClick?: (e: React.MouseEvent) => void;
	className?: string;
	isDisabled?: boolean;
	isLoading?: boolean;
	children: React.ReactNode;
};

const AppButton: React.FC<Props> = ({
	size = "middle",
	type = "primary",
	onClick,
	className,
	isDisabled,
	isLoading = false,
	children,
}: Props) => {
	// class expression
	const dynClass = cx("app-button", `app-button--${size}`, `app-button--${type}`, className);

	return (
		<button type="button" className={dynClass} onClick={onClick} disabled={isDisabled}>
			{isLoading && <LoadingOutlined style={{ fontSize: 14 }} spin />}
			{children}
		</button>
	);
};

export default AppButton;
