// GET REQUESTS DETAILS
export const GET_REQUEST_DETAIL = "GET_REQUEST_DETAIL";
export const GET_REQUEST_DETAIL_SUCCESS = "GET_REQUEST_DETAIL_SUCCESS";
export const GET_REQUEST_DETAIL_FAILURE = "GET_REQUEST_DETAIL_FAILURE";
export const GET_REQUEST_DETAIL_RESET = "GET_REQUEST_DETAIL_RESET";

// GET REQUESTS DETAIL TO COMPLETE
export const SET_REQUEST_DETAIL_TO_COMPLETE = "SET_REQUEST_DETAIL_TO_COMPLETE";
export const SET_REQUEST_DETAIL_TO_COMPLETE_SUCCESS = "SET_REQUEST_DETAIL_TO_COMPLETE_SUCCESS";
export const SET_REQUEST_DETAIL_TO_COMPLETE_FAILURE = "SET_REQUEST_DETAIL_TO_COMPLETE_FAILURE";
export const SET_REQUEST_DETAIL_TO_COMPLETE_RESET = "SET_REQUEST_DETAIL_TO_COMPLETE_RESET";

// GET REQUESTS DETAIL TO COMPLETE
export const SET_DOCUMENT_TO_COMPLETE = "SET_DOCUMENT_TO_COMPLETE";
export const SET_DOCUMENT_TO_COMPLETE_SUCCESS = "SET_DOCUMENT_TO_COMPLETE_SUCCESS";
export const SET_DOCUMENT_TO_COMPLETE_FAILURE = "SET_DOCUMENT_TO_COMPLETE_FAILURE";
export const SET_DOCUMENT_TO_COMPLETE_RESET = "SET_DOCUMENT_TO_COMPLETE_RESET";

// REMOVE DOCUMENT
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const REMOVE_DOCUMENT_SUCCESS = "REMOVE_DOCUMENT_SUCCESS";
export const REMOVE_DOCUMENT_FAILURE = "REMOVE_DOCUMENT_FAILURE";
export const REMOVE_DOCUMENT_RESET = "REMOVE_DOCUMENT_RESET";
