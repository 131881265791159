import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { API_ROOT } from "__utils/constants";
import { AxiosInstance, AxiosResponse } from "axios";
import * as constants from "./constants";

import { ADD_TO_WORKFLOW, GET_WORKFLOW_HISTORY, MOVE_WORKFLOW_STATE } from "./constants";
import {
	IAddToWorkflowPayload,
	IGetWorkflowHistoryPayload,
	IGetWorkflowUpdatePayload,
	IMoveWorkflowStatePayload,
} from "../__redux/types";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const moveWorkflowStateApi = async ({
	memberId,
	newState,
	override = false,
}: IMoveWorkflowStatePayload): Promise<AxiosResponse<any>> => {
	const url = MOVE_WORKFLOW_STATE.replace(":memberId", `${memberId}`);

	return axios.post(url, { newState, override }).catch(errorHandler);
};

export const getWorkflowSummaryApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(constants.GET_WORKFLOW_SUMMARY).catch(errorHandler);

export const getWorkflowUpdateApi = async ({ memberId }: IGetWorkflowUpdatePayload): Promise<AxiosResponse<any>> => {
	const url = constants.GET_WORKFLOW_UPDATE.replace(":memberId", `${memberId}`);
	return axios.get(url).catch(errorHandler);
};

export const addToWorkflowApi = async (payload: IAddToWorkflowPayload): Promise<AxiosResponse<any>> =>
	axios.post(ADD_TO_WORKFLOW, payload).catch(errorHandler);

export const getWorkflowHistoryApi = async ({ memberId }: IGetWorkflowHistoryPayload): Promise<AxiosResponse<any>> => {
	const url: string = GET_WORKFLOW_HISTORY.replace(":memberId", `${memberId}`);
	return axios.get(url).catch(errorHandler);
};
