import { ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";

/**
 * Get Notifications
 */

export interface CongratulateType {
	readonly id: number;
	readonly title: string;
	readonly subTitle: string;
	readonly description: string;
}

export interface NotificationType {
	readonly congratulateMessage: CongratulateType;
	readonly newsItems: Array<ObjectType>;
	readonly pendingActions: Array<ObjectType>;
}

export type GetNotificationType = {
	readonly notifications: NotificationType;
	readonly isGettingNotification: boolean;
	readonly isGetNotificationSuccess: boolean;
	readonly isGetNotificationError: boolean;
};

export const getNotificationDefaultValue: GetNotificationType = {
	notifications: {
		congratulateMessage: {
			id: 0,
			title: "",
			subTitle: "",
			description: "",
		},
		newsItems: [],
		pendingActions: [],
	},
	isGettingNotification: false,
	isGetNotificationSuccess: false,
	isGetNotificationError: false,
};

type GetNotificationAction = {
	type: typeof actionTypes.GET_NOTIFICATION;
};

type GetNotificationSuccessAction = {
	type: typeof actionTypes.GET_NOTIFICATION_SUCCESS;
};

type GetNotificationFailureAction = {
	type: typeof actionTypes.GET_NOTIFICATION_FAILURE;
};

type GetNotificationResetAction = {
	type: typeof actionTypes.GET_NOTIFICATION_RESET;
};

export type GetNotificationActionTypes =
	| GetNotificationAction
	| GetNotificationSuccessAction
	| GetNotificationFailureAction
	| GetNotificationResetAction;

/**
 * Send Referral
 */

export type SendReferralType = {
	readonly isSendingRefferal: boolean;
	readonly isSendReferralSuccess: boolean;
	readonly isSendReferralError: boolean;
};

export const SendReferralDefaultValue: SendReferralType = {
	isSendingRefferal: false,
	isSendReferralSuccess: false,
	isSendReferralError: false,
};

export type SendReferralPayloadType = {
	id: number;
	email: string;
};

type SendReferralAction = {
	type: typeof actionTypes.SEND_REFERRAL;
	payload: SendReferralPayloadType;
};

type SendReferralSuccessAction = {
	type: typeof actionTypes.SEND_REFERRAL_SUCCESS;
};

type SendReferralFailureAction = {
	type: typeof actionTypes.SEND_REFERRAL_FAILURE;
};

type SendReferralResetAction = {
	type: typeof actionTypes.SEND_REFERRAL_RESET;
};

export type SendReferralActionTypes =
	| SendReferralAction
	| SendReferralSuccessAction
	| SendReferralFailureAction
	| SendReferralResetAction;
