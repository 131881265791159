import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	GetRequestDetailActionTypes,
	getRequestDetailDefaultValue,
	GetRequestDetailType,
	RemoveDocumentActionTypes,
	removeDocumentDefaultValue,
	RemoveDocumentType,
	SetDocumentToCompleteActionTypes,
	setDocumentToCompleteDefaultValue,
	SetDocumentToCompleteType,
	SetRequestDetailToCompleteActionTypes,
	setRequestDetailToCompleteDefaultValue,
	SetRequestDetailToCompleteType,
} from "./types";

export const requestDetailStateReducer = (
	state = getRequestDetailDefaultValue,
	action: GetRequestDetailActionTypes
): GetRequestDetailType => {
	switch (action.type) {
		case actionTypes.GET_REQUEST_DETAIL:
			return {
				...state,
				isGettingRequestDetail: true,
			};
		case actionTypes.GET_REQUEST_DETAIL_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingRequestDetail: false,
				isGetRequestDetailSuccess: true,
				isGetRequestDetailError: false,
			};

		case actionTypes.GET_REQUEST_DETAIL_FAILURE:
			return {
				...state,
				isGettingRequestDetail: false,
				isGetRequestDetailSuccess: false,
				isGetRequestDetailError: true,
			};

		case actionTypes.GET_REQUEST_DETAIL_RESET:
			return {
				...getRequestDetailDefaultValue,
			};
		default:
			return state;
	}
};

// REQUEST DETAILS TO COMPLETE

export const requestToCompleteStateReducer = (
	state = setRequestDetailToCompleteDefaultValue,
	action: SetRequestDetailToCompleteActionTypes
): SetRequestDetailToCompleteType => {
	switch (action.type) {
		case actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE:
			return {
				...state,
				isSettingRequestDetailToComplete: true,
			};
		case actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_SUCCESS:
			return {
				...state,
				isSettingRequestDetailToComplete: false,
				isSetRequestDetailToCompleteSuccess: true,
				isSetRequestDetailToCompleteError: false,
			};

		case actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_FAILURE:
			return {
				...state,
				isSettingRequestDetailToComplete: false,
				isSetRequestDetailToCompleteSuccess: false,
				isSetRequestDetailToCompleteError: true,
			};

		case actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_RESET:
			return {
				...setRequestDetailToCompleteDefaultValue,
			};
		default:
			return state;
	}
};

// DOCUMENT TO COMPLETE

export const documentToCompleteStateReducer = (
	state = setDocumentToCompleteDefaultValue,
	action: SetDocumentToCompleteActionTypes
): SetDocumentToCompleteType => {
	switch (action.type) {
		case actionTypes.SET_DOCUMENT_TO_COMPLETE:
			return {
				...state,
				isSettingDocumentToComplete: true,
			};
		case actionTypes.SET_DOCUMENT_TO_COMPLETE_SUCCESS:
			return {
				...state,
				isSettingDocumentToComplete: false,
				isSetDocumentToCompleteSuccess: true,
				isSetDocumentToCompleteError: false,
			};

		case actionTypes.SET_DOCUMENT_TO_COMPLETE_FAILURE:
			return {
				...state,
				isSettingDocumentToComplete: false,
				isSetDocumentToCompleteSuccess: false,
				isSetDocumentToCompleteError: true,
			};

		case actionTypes.SET_DOCUMENT_TO_COMPLETE_RESET:
			return {
				...setDocumentToCompleteDefaultValue,
			};
		default:
			return state;
	}
};

export const removeDocFileStateReducer = (
	state = removeDocumentDefaultValue,
	action: RemoveDocumentActionTypes
): RemoveDocumentType => {
	switch (action.type) {
		case actionTypes.REMOVE_DOCUMENT:
			return {
				...state,
				fileKey: action.payload.fileKey,
				isRemovingDocument: true,
			};
		case actionTypes.REMOVE_DOCUMENT_SUCCESS:
			return {
				...state,
				isRemovingDocument: false,
				isRemoveDocumentSuccess: true,
				isRemoveDocumentError: false,
			};

		case actionTypes.REMOVE_DOCUMENT_FAILURE:
			return {
				...state,
				isRemovingDocument: false,
				isRemoveDocumentSuccess: false,
				isRemoveDocumentError: true,
			};

		case actionTypes.REMOVE_DOCUMENT_RESET:
			return {
				...removeDocumentDefaultValue,
			};
		default:
			return state;
	}
};

export const requestsDetailsReducer = combineReducers({
	requestDetailState: requestDetailStateReducer,
	requestToCompleteState: requestToCompleteStateReducer,
	documentToCompleteState: documentToCompleteStateReducer,
	removeDocFileState: removeDocFileStateReducer,
});
