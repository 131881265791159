import { combineReducers } from "redux";

import * as actionTypes from "./actionTypes";
import {
	GetInvestmentActionTypes,
	getInvestmentDefaultValue,
	GetInvestmentType,
	GetPortfolioActionTypes,
	getPortfolioDefaultValue,
	GetPortfolioType,
	GetTransactionActionTypes,
	getTransactionDefaultValue,
	GetTransactionType,
} from "./types";

// PORTFOLIO REDUCERS
export const getPortfolioStateReducer = (
	state = getPortfolioDefaultValue,
	action: GetPortfolioActionTypes
): GetPortfolioType => {
	switch (action.type) {
		case actionTypes.GET_PORTFOLIO:
			return {
				...state,
				isGettingPortfolio: true,
			};
		case actionTypes.GET_PORTFOLIO_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingPortfolio: true,
				isGetPortfolioSuccess: true,
				isGetPortfolioError: false,
			};

		case actionTypes.GET_PORTFOLIO_FAILURE:
			return {
				...state,
				isGettingPortfolio: false,
				isGetPortfolioSuccess: false,
				isGetPortfolioError: true,
			};

		case actionTypes.GET_PORTFOLIO_RESET:
			return {
				...state,
				...getPortfolioDefaultValue,
			};
		default:
			return state;
	}
};

// INVESTMENT REDUCERS
export const investmentStateReducer = (
	state = getInvestmentDefaultValue,
	action: GetInvestmentActionTypes
): GetInvestmentType => {
	switch (action.type) {
		case actionTypes.GET_INVESTMENT:
			return {
				...state,
				isGettingInvestment: true,
			};
		case actionTypes.GET_INVESTMENT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingInvestment: false,
				isGetInvestmentSuccess: true,
				isGetInvestmentError: false,
			};

		case actionTypes.GET_INVESTMENT_FAILURE:
			return {
				...state,
				isGettingInvestment: false,
				isGetInvestmentSuccess: false,
				isGetInvestmentError: true,
			};

		case actionTypes.GET_INVESTMENT_RESET:
			return {
				...getInvestmentDefaultValue,
			};
		default:
			return state;
	}
};

// TRANSACTION REDUCERS

export const transactionStateReducer = (
	state = getTransactionDefaultValue,
	action: GetTransactionActionTypes
): GetTransactionType => {
	switch (action.type) {
		case actionTypes.GET_TRANSACTION:
			return {
				...state,
				isGettingTransaction: true,
			};
		case actionTypes.GET_TRANSACTION_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingTransaction: false,
				isGetTransactionSuccess: true,
				isGetTransactionError: false,
			};

		case actionTypes.GET_TRANSACTION_FAILURE:
			return {
				...state,
				isGettingTransaction: false,
				isGetTransactionSuccess: false,
				isGetTransactionError: true,
			};

		case actionTypes.GET_TRANSACTION_RESET:
			return {
				...getTransactionDefaultValue,
			};
		default:
			return state;
	}
};

export const dashboardReducer = combineReducers({
	getPortfolioState: getPortfolioStateReducer,
	investmentState: investmentStateReducer,
	transactionState: transactionStateReducer,
});
