import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	EditProfileActionTypes,
	EditProfileDefaultValue,
	EditProfileType,
	GetProfileActionTypes,
	getProfileDefaultValue,
	GetProfileType,
	IVerifyAuth,
	IVerifyAuthActions,
	verifyAuthDefaultValue,
} from "./types";

export const getProfileStateReducer = (
	state = getProfileDefaultValue,
	action: GetProfileActionTypes
): GetProfileType => {
	switch (action.type) {
		case actionTypes.GET_PROFILE:
			return {
				...state,
				isGettingProfile: true,
			};
		case actionTypes.GET_PROFILE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingProfile: false,
				isGetProfileSuccess: true,
				isGetProfileError: false,
			};

		case actionTypes.GET_PROFILE_FAILURE:
			return {
				...state,
				isGettingProfile: false,
				isGetProfileSuccess: false,
				isGetProfileError: true,
			};

		case actionTypes.GET_PROFILE_RESET:
			return {
				...state,
				...getProfileDefaultValue,
			};
		default:
			return state;
	}
};

export const editProfileStateReducer = (
	state = EditProfileDefaultValue,
	action: EditProfileActionTypes
): EditProfileType => {
	switch (action.type) {
		case actionTypes.EDIT_PROFILE:
			return {
				...state,
				isEditingProfile: true,
			};
		case actionTypes.EDIT_PROFILE_SUCCESS:
			return {
				...state,
				isEditingProfile: false,
				isEditProfileSuccess: true,
				isEditProfileError: false,
			};

		case actionTypes.EDIT_PROFILE_FAILURE:
			return {
				...state,
				isEditingProfile: false,
				isEditProfileSuccess: false,
				isEditProfileError: true,
			};

		case actionTypes.EDIT_PROFILE_RESET:
			return {
				...state,
				...EditProfileDefaultValue,
			};
		default:
			return state;
	}
};

export const verifyAuthReducer = (state = verifyAuthDefaultValue, action: IVerifyAuthActions): IVerifyAuth => {
	switch (action.type) {
		case actionTypes.VERIFY_AUTH:
			return {
				...state,
				verifyingAuth: true,
			};
		case actionTypes.VERIFY_AUTH_SUCCESS:
			return {
				...state,
				verifyingAuth: false,
				verifyAuthSuccess: true,
				verifyAuthFailure: false,
			};

		case actionTypes.VERIFY_AUTH_FAILURE:
			return {
				...state,
				verifyingAuth: false,
				verifyAuthSuccess: false,
				verifyAuthFailure: true,
			};
		default:
			return state;
	}
};

export const accountReducer = combineReducers({
	getProfileState: getProfileStateReducer,
	editProfileState: editProfileStateReducer,
	verifyAuthState: verifyAuthReducer,
});
