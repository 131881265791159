import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import * as urls from "__utils/constants";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { memberDocumentsSlr } from "./selectors";
import { DocumentTreeType, GetMemberDocumentsType } from "./types";

// GET ESTABLISH DOCUMENTS
const getDocuments = function* (action: any): any {
	const memberDocumentTree: GetMemberDocumentsType = yield select(memberDocumentsSlr);
	const { data: memberDocumentTreeData } = memberDocumentTree;
	const { envelopeId } = action.payload;

	try {
		const response: ResponseGeneratorType = yield call(api.getDocumentsAPI, envelopeId);
		const { status, data: documentData } = response;

		if (status === 200) {
			const updateMemberDocFileEnvelope = memberDocumentTreeData.documentTree.map((data: DocumentTreeType) => {
				if (data.envelopeId === envelopeId) {
					return { ...data, files: documentData };
				}
				return { ...data };
			});

			yield put({
				type: actionTypes.GET_DOCUMENTS_SUCCESS,
				data: documentData,
			});
			yield put({
				type: actionTypes.GET_MEMBER_DOCUMENTS_SUCCESS,
				data: {
					...memberDocumentTreeData,
					documentTree: updateMemberDocFileEnvelope,
				},
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			// DocuSign envelope does not exist - Indicate this and wait 10 seconds
			yield put({ type: actionTypes.GET_DOCUMENTS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOCUMENTS_FAILURE });
	}
};

export const getDocumentsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOCUMENTS, getDocuments);
};

// GET MEMBER DOCUMENTS
const getMemberDocuments = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getMemberDocumentsAPI);
		const { status, data } = response;

		if (status === 200) {
			const { documentTree, folderLookup } = data;
			yield put({
				type: actionTypes.GET_MEMBER_DOCUMENTS_SUCCESS,
				data: {
					documentTree,
					memberDocFolderLookup: folderLookup,
				},
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_MEMBER_DOCUMENTS_RESET });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_MEMBER_DOCUMENTS_RESET });
	}
};

export const getMemberDocumentsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_MEMBER_DOCUMENTS, getMemberDocuments);
};

// GET MEMBER DOCUMENTS DETAILS
const getMemberDocumentDetails = function* (action: any): any {
	try {
		const { key } = action.payload;
		const memberDocumentTree: GetMemberDocumentsType = yield select(memberDocumentsSlr);
		const { data: memberDocumentTreeData } = memberDocumentTree;
		const response: ResponseGeneratorType = yield call(api.getMemberDocumentDetailsAPI, key);
		const { status, data: documentDetailsData } = response;

		if (status === 200) {
			const updateMemberDocFile = memberDocumentTreeData.documentTree.map((data: DocumentTreeType) => {
				if (data.key === key) {
					return { ...data, files: documentDetailsData.documentTree };
				}
				return { ...data };
			});
			yield put({
				type: actionTypes.GET_MEMBER_DOCUMENT_DETAILS_SUCCESS,
			});

			yield put({
				type: actionTypes.GET_MEMBER_DOCUMENTS_SUCCESS,
				data: {
					...memberDocumentTreeData,
					documentTree: updateMemberDocFile,
				},
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_MEMBER_DOCUMENT_DETAILS_RESET });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_MEMBER_DOCUMENT_DETAILS_RESET });
	}
};

export const getMemberDocumentDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_MEMBER_DOCUMENT_DETAILS, getMemberDocumentDetails);
};

// MEMBER DOCS CATEGORIES

const getMemberDocumentCategories = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getMemberDocsCategoryAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_MEMBER_DOC_CATEGORIES_SUCCESS,
				data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_MEMBER_DOC_CATEGORIES_RESET });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_MEMBER_DOC_CATEGORIES_RESET });
	}
};

export const getMemberDocumentCategoriesSaga = function* (): any {
	yield takeLatest(actionTypes.GET_MEMBER_DOC_CATEGORIES, getMemberDocumentCategories);
};

// UPLOAD DOCUMENT
const uploadDocument = function* (action: any): any {
	const { payload } = action;
	try {
		const response: ResponseGeneratorType = yield call(api.uploadDocumentApi, payload);
		const { status } = response;
		if (status === 200) {
			yield put({
				type: actionTypes.UPLOAD_DOCUMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.UPLOAD_DOCUMENT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.UPLOAD_DOCUMENT_FAILURE });
	}
};

export const uploadDocumentSaga = function* (): any {
	yield takeLatest(actionTypes.UPLOAD_DOCUMENT, uploadDocument);
};
