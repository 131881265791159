import React from "react";
import AppButton from "__common/AppButton";
import AppLink from "__common/AppLink";
import { endpoints } from "__utils/constants";
import "./style.scss";

type Props = unknown;

const Footer: React.FC<Props> = () => {
	return (
		<div className="footer">
			{/* sign out button */}
			<div className="footer__sign-out-button">
				<AppLink isExternal route={endpoints.websiteSignOutURL}>
					<AppButton type="primary" size="large">
						SIGN OUT
					</AppButton>
				</AppLink>
			</div>
			{/* text container */}
			<div className="footer__text_container">
				<p className="footer__text">© Copyright Selfmade 2018</p>
				<AppLink
					className="footer__links footer__terms"
					route={endpoints.websiteTermsURL}
					isExternal
					openInNewTab
				>
					Terms & conditions
				</AppLink>
				<AppLink
					className="footer__links footer__privacy"
					route={endpoints.websitePrivacyPolicyURL}
					isExternal
					openInNewTab
				>
					Privacy policy
				</AppLink>
			</div>
		</div>
	);
};

export default Footer;
