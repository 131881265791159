import React from "react";
import SearchIcon from "__assets/svg/searching.svg";
import "./style.scss";

type Props = {
	headerText: string;
	notificationText: string;
	pageMsg?: string;
};

const defaultNotificationText =
	"In order to see data here you must sign your SMSF documentation, roll over funds into your new CMA and start investing your SMSF's money.";

const defaultPageMsg = "NO DATA AVAILABLE";

const EmptyData: React.FC<Props> = ({ headerText, notificationText, pageMsg }: Props) => {
	return (
		<div className="empty-data">
			<p className="empty-data__header-text">{headerText}</p>

			<img src={SearchIcon} className="empty-data__icon" alt="Empty data icon" />

			<p className="empty-data__nodata-text">{pageMsg || defaultPageMsg}</p>

			<p className="empty-data__notification-text">{notificationText || defaultNotificationText}</p>
		</div>
	);
};

export default EmptyData;
