import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { EditProfilePayloadType } from "../__redux/types";
import { FETCH_PROFILE, UPDATE_PROFILE, VERIFY_AUTH } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const verifyUserAuthApi = async (): Promise<AxiosResponse<any>> => {
	return axios.get(VERIFY_AUTH).catch(errorHandler);
};

export const getUserProfileApi = async (): Promise<AxiosResponse<any>> => {
	return axios.get(FETCH_PROFILE).catch(errorHandler);
};

export const updateUserProfileApi = async (payload: EditProfilePayloadType): Promise<AxiosResponse<any>> => {
	return axios.post(UPDATE_PROFILE, payload).catch(errorHandler);
};
