// fetch RequestsDetails
export const FETCH_REQUESTS_DETAILS = "/requests/:requestId/";

// Request To COmplete
export const SET_REQUESTS_TO_COMPLETE = "/requests/complete/:requestId/";

// Document To Complete
export const SET_DOCUMENT_TO_COMPLETE = "/requests/complete/:requestId/document/:documentId/";

// Upload Documents
export const UPLOAD_DOCUMENT = "/member-documents/upload_request_document/";

// Download Documents
export const DOWNLOAD_DOCUMENT = "/member-documents/fetch_document/";

// Remove Documents
export const REMOVE_DOCUMENT = "/member-documents/delete_document/:fileKey/";
