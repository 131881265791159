import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";

export type IActions = {
	readonly current: IStateDetails | undefined;
	readonly next: IStateDetails[];
};

export type IWorkflowRender = {
	readonly loading: boolean;
	readonly fetchFailed: boolean;
	readonly notAssigned: boolean;
	readonly history: IStatesHistory;
	readonly flatHistory: IFlatStatesHistory;
	readonly actions: IActions;
	readonly workflow: IWorkflowDetails;
	readonly stages: IStages;
	readonly states: IStates;
	readonly progress: number;
	readonly movingWorkflowState: boolean;
	readonly newState: number;
	readonly onMoveState: (stateId: number, override?: boolean) => void;
	readonly visuallyCurrentState: (state: IStateDetails, actions: IActions) => boolean;
};

export type IFlatStatesHistory = IStateDetails[];

export type IStatesHistory = {
	[key: string]: {
		readonly stage: string;
		readonly states: IStateDetails[];
		readonly complete: boolean;
		readonly hasFailure: boolean;
		readonly current: boolean;
	};
};

export type IBuiltHistory = {
	readonly history: IStatesHistory;
	readonly flatHistory: IFlatStatesHistory;
};

export type IMoveWorkflowState = {
	readonly newState: number;
	readonly movingWorkflowState: boolean;
	readonly moveWorkflowStateSuccess: boolean;
	readonly moveWorkflowStateFailure: boolean;
	readonly moveWorkflowStateError: ApiErrorType;
};

export const moveWorkflowStateDefaultValue: IMoveWorkflowState = {
	newState: 0,
	movingWorkflowState: false,
	moveWorkflowStateSuccess: false,
	moveWorkflowStateFailure: false,
	moveWorkflowStateError: {},
};

export type IMoveWorkflowStatePayload = {
	readonly memberId: number;
	readonly newState: number;
	readonly override?: boolean;
};

export type IMoveWorkflowStateAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_STATE;
	payload: IMoveWorkflowStatePayload;
};

export type IMoveWorkflowStateSuccessAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_STATE_SUCCESS;
};

export type IMoveWorkflowStateFailureAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_STATE_FAILURE;
};

export type IMoveWorkflowStateResetAction = {
	type: typeof actionTypes.MOVE_WORKFLOW_STATE_RESET;
};

export type IMoveWorkflowStateActions =
	| IMoveWorkflowStateAction
	| IMoveWorkflowStateSuccessAction
	| IMoveWorkflowStateFailureAction
	| IMoveWorkflowStateResetAction;

/**
 * Get workflow summary
 */

export type IStateDetails = {
	id: number;
	adminAction: string;
	workflow: number;
	title: string;
	initial: boolean;
	next: number[];
	adminLabel: string;
	memberLabel: string;
	description: string;
	status: string | null;
	type: string;
	action: string;
	url: string;
	message?: string;
};

export type IStates = { [key: string]: IStateDetails };

export type IStageDetails = {
	readonly id: number;
	readonly title: string;
	readonly context: string;
	readonly states: number[];
};

export type IStages = { [key: string]: IStageDetails };

export type IWorkflowDetails = {
	readonly id: number;
	readonly title: string;
	readonly type: string;
	readonly stages: number[];
	readonly states: number[];
};

export type IWorkflows = { [key: string]: IWorkflowDetails };

export type IWorkflowSummary = {
	readonly workflows: IWorkflows;
	readonly stages: IStages;
	readonly states: IStates;
};

export type IGetWorkflowSummary = {
	readonly workflowSummary: IWorkflowSummary;
	readonly gettingWorkflowSummary: boolean;
	readonly getWorkflowSummarySuccess: boolean;
	readonly getWorkflowSummaryFailure: boolean;
	readonly getWorkflowSummaryError: ApiErrorType;
};

export const getWorkflowSummaryDefaultValue = {
	workflowSummary: {
		workflows: {},
		stages: {},
		states: {},
		stateWorkflowPairs: {},
	},
	gettingWorkflowSummary: false,
	getWorkflowSummarySuccess: false,
	getWorkflowSummaryFailure: false,
	getWorkflowSummaryError: {},
};

export type IGetWorkflowSummaryCtr = {
	(): IGetWorkflowSummaryActions;
};

export type IGetWorkflowSummaryAction = {
	type: typeof actionTypes.GET_WORKFLOW_SUMMARY;
};

export type IGetWorkflowSummarySuccessAction = {
	type: typeof actionTypes.GET_WORKFLOW_SUMMARY_SUCCESS;
};

export type IGetWorkflowSummaryFailureAction = {
	type: typeof actionTypes.GET_WORKFLOW_SUMMARY_FAILURE;
};

export type IGetWorkflowSummaryCancelAction = {
	type: typeof actionTypes.GET_WORKFLOW_SUMMARY_CANCEL;
};

export type IGetWorkflowSummaryResetAction = {
	type: typeof actionTypes.GET_WORKFLOW_SUMMARY_RESET;
};

export type IGetWorkflowSummaryActions =
	| IGetWorkflowSummaryAction
	| IGetWorkflowSummarySuccessAction
	| IGetWorkflowSummaryFailureAction
	| IGetWorkflowSummaryCancelAction
	| IGetWorkflowSummaryResetAction;

/**
 * Get workflow update
 */

export type INextWorkflowState = { [key: string]: number[] };
export type INewWorkflowStatuses = {
	[key: string]: {
		readonly status: string;
		readonly message?: string;
	};
};

export type IWorkflowUpdate = {
	next: INextWorkflowState;
	stateHistory: INewWorkflowStatuses;
};

export type IGetWorkflowUpdate = {
	readonly workflowUpdate: IWorkflowUpdate;
	readonly gettingWorkflowUpdate: boolean;
	readonly getWorkflowUpdateSuccess: boolean;
	readonly getWorkflowUpdateFailure: boolean;
	readonly getWorkflowUpdateError: ApiErrorType;
};

export const getWorkflowUpdateDefaultValue: IGetWorkflowUpdate = {
	workflowUpdate: {
		next: {},
		stateHistory: {},
	},
	gettingWorkflowUpdate: false,
	getWorkflowUpdateSuccess: false,
	getWorkflowUpdateFailure: false,
	getWorkflowUpdateError: {},
};

export type IGetWorkflowUpdatePayload = {
	readonly memberId: number;
};

export type IGetWorkflowUpdateCtr = {
	(payload: IGetWorkflowUpdatePayload): IGetWorkflowUpdateActions;
};

export type IWorkflowUpdateAction = {
	type: typeof actionTypes.GET_WORKFLOW_UPDATE;
	payload: IGetWorkflowUpdatePayload;
};

export type IWorkflowUpdateASuccessAction = {
	type: typeof actionTypes.GET_WORKFLOW_UPDATE_SUCCESS;
};

export type IWorkflowUpdateFailureAction = {
	type: typeof actionTypes.GET_WORKFLOW_UPDATE_FAILURE;
};

export type IWorkflowUpdateResetAction = {
	type: typeof actionTypes.GET_WORKFLOW_UPDATE_RESET;
};

export type IGetWorkflowUpdateActions =
	| IWorkflowUpdateAction
	| IWorkflowUpdateASuccessAction
	| IWorkflowUpdateFailureAction
	| IWorkflowUpdateResetAction;

/**
 * Add to workflow
 */

export type IAddToWorkflow = {
	readonly addingToWorkflow: boolean;
	readonly addToWorkflowFailure: boolean;
	readonly addToWorkflowSuccess: boolean;
	readonly addToWorkflowError: ApiErrorType;
};

export const addToWorkflowDefaultValue: IAddToWorkflow = {
	addingToWorkflow: false,
	addToWorkflowFailure: false,
	addToWorkflowSuccess: false,
	addToWorkflowError: {},
};

export type IAddToWorkflowPayload = {
	readonly members: number[];
	readonly workflow: number;
	readonly dueDate: string;
	readonly year: number;
};

export type IAddToWorkflowCtr = {
	(payload: IAddToWorkflowPayload): IAddToWorkflowActions;
};

type IAddToWorkflowAction = {
	type: typeof actionTypes.ADD_TO_WORKFLOW;
	payload: IAddToWorkflowPayload;
};

type IAddToWorkflowSuccessAction = {
	type: typeof actionTypes.ADD_TO_WORKFLOW_SUCCESS;
};

type IAddToWorkflowFailureAction = {
	type: typeof actionTypes.ADD_TO_WORKFLOW_FAILURE;
};

type IAddToWorkflowResetAction = {
	type: typeof actionTypes.ADD_TO_WORKFLOW_RESET;
};

export type IAddToWorkflowActions =
	| IAddToWorkflowAction
	| IAddToWorkflowSuccessAction
	| IAddToWorkflowFailureAction
	| IAddToWorkflowResetAction;

/**
 * Get workflow history
 */

export type IWorkflowHistoryDetails = {
	readonly id: string;
	readonly name: string;
	readonly dateStart: string;
	readonly dateEnd: string;
};

export type IWorkflowHistory = IWorkflowHistoryDetails[];

export type IGetWorkflowHistory = {
	readonly workflowHistory: IWorkflowHistory;
	readonly gettingWorkflowHistory: boolean;
	readonly getWorkflowHistorySuccess: boolean;
	readonly getWorkflowHistoryFailure: boolean;
	readonly getWorkflowHistoryError: ApiErrorType;
};

export const getWorkflowHistoryDefaultValue: IGetWorkflowHistory = {
	workflowHistory: [],
	gettingWorkflowHistory: false,
	getWorkflowHistoryFailure: false,
	getWorkflowHistorySuccess: false,
	getWorkflowHistoryError: {},
};

export type IGetWorkflowHistoryPayload = {
	readonly memberId: number;
};

export type IGetWorkflowHistoryCtr = {
	(payload: IGetWorkflowHistoryPayload): IGetWorkflowHistoryActions;
};

type IGetWorkflowHistoryAction = {
	type: typeof actionTypes.GET_WORKFLOW_HISTORY;
	payload: IGetWorkflowHistoryPayload;
};

type IGetWorkflowHistorySuccessAction = {
	type: typeof actionTypes.GET_WORKFLOW_HISTORY_SUCCESS;
};

type IGetWorkflowHistoryFailureAction = {
	type: typeof actionTypes.GET_WORKFLOW_HISTORY_FAILURE;
};

type IGetWorkflowHistoryResetAction = {
	type: typeof actionTypes.GET_WORKFLOW_HISTORY_RESET;
};

export type IGetWorkflowHistoryActions =
	| IGetWorkflowHistoryAction
	| IGetWorkflowHistorySuccessAction
	| IGetWorkflowHistoryFailureAction
	| IGetWorkflowHistoryResetAction;
