import React from "react";
import { Link } from "react-router-dom";

type Props = {
	isExternal?: boolean;
	openInNewTab?: boolean;
	route: string;
	children: React.ReactNode;
	onClick?: (e: React.MouseEvent) => void;
	className?: string;
};

const AppLink: React.FC<Props> = ({ isExternal, route, openInNewTab, children, className, onClick }: Props) => {
	return isExternal ? (
		<a href={route} className={className} onClick={onClick} {...(openInNewTab && { target: "_blank" })}>
			{children}
		</a>
	) : (
		<Link to={route} className={className} onClick={onClick}>
			{children}
		</Link>
	);
};

export default AppLink;
