import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	GetDocumentsActionTypes,
	getdocumentsDefaultValue,
	GetDocumentsType,
	GetMemberDocCategoriesActionTypes,
	getMemberDocCategoriesDefaultValue,
	GetMemberDocCategoriesType,
	GetMemberDocumentsActionTypes,
	getMemberDocumentsDefaultValue,
	GetMemberDocumentsType,
	UploadDocumentActionTypes,
	uploadDocumentDefaultValue,
	UploadDocumentType,
	getMemberDocumentDetailsDefaultValue,
	GetMemberDocumentDetailsActionTypes,
	GetMemberDocumentDetailsType,
} from "./types";

// DOCUMENTS

export const _documentsReducer = (
	state = getdocumentsDefaultValue,
	action: GetDocumentsActionTypes
): GetDocumentsType => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENTS:
			return {
				...state,
				envelopeId: action.payload.envelopeId,
				isGettingDocuments: true,
			};
		case actionTypes.GET_DOCUMENTS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingDocuments: false,
				isGetDocumentsSuccess: true,
				isGetDocumentsError: false,
			};

		case actionTypes.GET_DOCUMENTS_FAILURE:
			return {
				...state,
				isGettingDocuments: false,
				isGetDocumentsSuccess: false,
				isGetDocumentsError: true,
			};

		case actionTypes.GET_DOCUMENTS_RESET:
			return {
				...getdocumentsDefaultValue,
			};
		default:
			return state;
	}
};

// MEMBER DOCUMENTS

export const memberDocumentsReducer = (
	state = getMemberDocumentsDefaultValue,
	action: GetMemberDocumentsActionTypes
): GetMemberDocumentsType => {
	switch (action.type) {
		case actionTypes.GET_MEMBER_DOCUMENTS:
			return {
				...state,
				isGettingMemberDocDetails: true,
			};
		case actionTypes.GET_MEMBER_DOCUMENTS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingMemberDocDetails: false,
				isGetMemberDocumentsSuccess: true,
				isGetMemberDocumentsError: false,
			};

		case actionTypes.GET_MEMBER_DOCUMENTS_FAILURE:
			return {
				...state,
				isGettingMemberDocDetails: false,
				isGetMemberDocumentsSuccess: false,
				isGetMemberDocumentsError: true,
			};

		case actionTypes.GET_MEMBER_DOCUMENTS_RESET:
			return {
				...getMemberDocumentsDefaultValue,
			};
		default:
			return state;
	}
};

// MEMBER DOC DETAILS

export const memberDocumentDetailsReducer = (
	state = getMemberDocumentDetailsDefaultValue,
	action: GetMemberDocumentDetailsActionTypes
): GetMemberDocumentDetailsType => {
	switch (action.type) {
		case actionTypes.GET_MEMBER_DOCUMENT_DETAILS:
			return {
				...state,
				key: action.payload.key,
				isGettingMemberDocumentDetails: true,
			};
		case actionTypes.GET_MEMBER_DOCUMENT_DETAILS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingMemberDocumentDetails: false,
				isGetMemberDocumentDetailsSuccess: true,
				isGetMemberDocumentDetailsError: false,
			};

		case actionTypes.GET_MEMBER_DOCUMENT_DETAILS_FAILURE:
			return {
				...state,
				isGettingMemberDocumentDetails: false,
				isGetMemberDocumentDetailsSuccess: false,
				isGetMemberDocumentDetailsError: true,
			};

		case actionTypes.GET_MEMBER_DOCUMENT_DETAILS_RESET:
			return {
				...getMemberDocumentDetailsDefaultValue,
			};
		default:
			return state;
	}
};

// MEMBER DOC CATEGORIES

export const memberDocCategoriesReducer = (
	state = getMemberDocCategoriesDefaultValue,
	action: GetMemberDocCategoriesActionTypes
): GetMemberDocCategoriesType => {
	switch (action.type) {
		case actionTypes.GET_MEMBER_DOC_CATEGORIES:
			return {
				...state,
				isGettingMemberDocCategories: true,
			};
		case actionTypes.GET_MEMBER_DOC_CATEGORIES_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingMemberDocCategories: false,
				isGettingMemberDocCategoriesSuccess: true,
				isGettingMemberDocCategoriesError: false,
			};

		case actionTypes.GET_MEMBER_DOC_CATEGORIES_FAILURE:
			return {
				...state,
				isGettingMemberDocCategories: false,
				isGettingMemberDocCategoriesSuccess: false,
				isGettingMemberDocCategoriesError: true,
			};

		case actionTypes.GET_MEMBER_DOC_CATEGORIES_RESET:
			return {
				...getMemberDocCategoriesDefaultValue,
			};
		default:
			return state;
	}
};

export const uploadDocumentReducer = (
	state = uploadDocumentDefaultValue,
	action: UploadDocumentActionTypes
): UploadDocumentType => {
	switch (action.type) {
		case actionTypes.UPLOAD_DOCUMENT:
			return {
				...state,
				isUploadingDocument: true,
			};
		case actionTypes.UPLOAD_DOCUMENT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isUploadingDocument: false,
				isUploadDocumentSuccess: true,
				isUploadDocumentError: false,
			};

		case actionTypes.UPLOAD_DOCUMENT_FAILURE:
			return {
				...state,
				isUploadingDocument: false,
				isUploadDocumentSuccess: false,
				isUploadDocumentError: true,
			};

		case actionTypes.UPLOAD_DOCUMENT_RESET:
			return {
				...uploadDocumentDefaultValue,
			};
		default:
			return state;
	}
};

export const documentsReducer = combineReducers({
	documents: _documentsReducer,
	memberDocuments: memberDocumentsReducer,
	memberDocumentDetails: memberDocumentDetailsReducer,
	memberDocCategories: memberDocCategoriesReducer,
	uploadDocument: uploadDocumentReducer,
});
