import * as actionTypes from "./actionTypes";

/**
 * Get Billing
 */

export interface BillingType {
	readonly id?: number;
	readonly debitDate: string;
	readonly invoiceId: string;
	readonly amount: number;
	readonly status: string;
	readonly scheduleAmount: string;
	readonly description: string;
}

export type GetBillingType = {
	readonly data: Array<BillingType>;
	readonly isGettingBilling: boolean;
	readonly isGetBillingSuccess: boolean;
	readonly isGetBillingError: boolean;
};

export const getBillingDefaultValue: GetBillingType = {
	data: [],
	isGettingBilling: false,
	isGetBillingSuccess: false,
	isGetBillingError: false,
};

type GetBillingAction = {
	type: typeof actionTypes.GET_BILLING;
};

type GetBillingSuccessAction = {
	type: typeof actionTypes.GET_BILLING_SUCCESS;
};

type GetBillingFailureAction = {
	type: typeof actionTypes.GET_BILLING_FAILURE;
};

type GetBillingResetAction = {
	type: typeof actionTypes.GET_BILLING_RESET;
};

export type GetBillingActionTypes =
	| GetBillingAction
	| GetBillingSuccessAction
	| GetBillingFailureAction
	| GetBillingResetAction;
