import { all, fork } from "redux-saga/effects";
import * as WorkflowSaga from "__common/Workflow/__redux/sagas";
import * as RightMenuSaga from "__layout/NotificationPanel/__redux/sagas";
import * as AccountSaga from "__pages/Account/__redux/sagas";
import * as BillingSaga from "__pages/Billing/__redux/sagas";
import * as DashboardSaga from "__pages/Dashboard/__redux/sagas";
import * as DocumentsSaga from "__pages/Documents/__redux/sagas";
import * as FundSaga from "__pages/Fund/__redux/sagas";
import * as RequestDetailSaga from "__pages/Requests/DocumentRequests/DocumentRequestDetail/__redux/sagas";
import * as RequestSaga from "__pages/Requests/DocumentRequests/__redux/sagas";
import { getSigningDetailsSaga } from "__pages/Requests/SigningRequests/__redux/sagas";

/* -----------------------------------------------------------------------------
     ROOT SAGA
 ----------------------------------------------------------------------------- */

// this root saga groups all other watcher sagas together
export default function* rootSaga(): any {
	yield all(
		[
			DashboardSaga.getTransactionSaga,
			DashboardSaga.getInvestmentSaga,
			DashboardSaga.getPortfolioSaga,
			AccountSaga.getProfileSaga,
			AccountSaga.editProfileSaga,
			AccountSaga.verifyAuthSaga,
			RequestSaga.getRequestsSaga,
			RequestDetailSaga.getRequestDetailsSaga,
			RequestDetailSaga.setRequestToCompleteSaga,
			RequestDetailSaga.setDocumentToCompleteSaga,
			RequestDetailSaga.removeDocFileSaga,
			FundSaga.getFundSaga,
			FundSaga.getBasicFundSaga,
			DocumentsSaga.getMemberDocumentsSaga,
			DocumentsSaga.getMemberDocumentCategoriesSaga,
			DocumentsSaga.getDocumentsSaga,
			DocumentsSaga.uploadDocumentSaga,
			DocumentsSaga.getMemberDocumentDetailsSaga,
			BillingSaga.getBillingSaga,
			RightMenuSaga.getNotificationsSaga,
			RightMenuSaga.sendReferralSaga,
			getSigningDetailsSaga,
			WorkflowSaga.getWorkflowSummarySaga,
			WorkflowSaga.getWorkflowUpdateSaga,
			WorkflowSaga.moveWorkflowStateSaga,
			WorkflowSaga.addToWorkflowSaga,
			WorkflowSaga.getWorkflowHistorySaga,
		].map(fork)
	);
}
