import * as actionTypes from "./actionTypes";
import {
	EditProfileActionTypes,
	EditProfilePayloadType,
	GetProfileActionTypes,
	IVerifyAuthActions,
	IVerifyAuthPayload,
} from "./types";

/* -----------------------------------------------------------------------------
    ACTIONS CREATORS PROFILE
----------------------------------------------------------------------------- */

// GET PROFILE DATA
export const getProfileCtr = (): GetProfileActionTypes => {
	return {
		type: actionTypes.GET_PROFILE,
	};
};

export const getProfileResetCtr = (): GetProfileActionTypes => {
	return {
		type: actionTypes.GET_PROFILE_RESET,
	};
};

// EDIT PROFILE DATA
export const editProfileCtr = (payload: EditProfilePayloadType): EditProfileActionTypes => {
	return {
		type: actionTypes.EDIT_PROFILE,
		payload,
	};
};

export const editProfileResetCtr = (): EditProfileActionTypes => {
	return {
		type: actionTypes.EDIT_PROFILE_RESET,
	};
};

export const verifyAuthCtr = (payload: IVerifyAuthPayload): IVerifyAuthActions => {
	return {
		type: actionTypes.VERIFY_AUTH,
		payload,
	};
};
