import * as actionTypes from "./actionTypes";

/**
 * Get Requests
 */
export interface SignersType {
	readonly name: string;
	readonly status: string;
}
export interface SigningRequestsDetailsType {
	readonly title: string;
	readonly envelopeId: string;
	readonly dueDate: string;
	readonly requested: number;
	readonly completed: number;
	readonly description: string;
	readonly signers: Array<SignersType>;
}

export interface RequestsType {
	readonly id: number;
	readonly title: string;
	readonly state: string;
	readonly requested: number;
	readonly completed: number;
	readonly alert: string;
	readonly type: string;
	readonly description: string;
	readonly envelopeId: string;
	readonly dueDate: string;
	readonly updatedDate: string;
	readonly signingRequestsDetails?: SigningRequestsDetailsType;
}

export type GetRequestsType = {
	readonly data: Array<RequestsType>;
	readonly isGettingRequests: boolean;
	readonly isGetRequestsSuccess: boolean;
	readonly isGetRequestsError: boolean;
};

export const getRequestsDefaultValue: GetRequestsType = {
	data: [],
	isGettingRequests: false,
	isGetRequestsSuccess: false,
	isGetRequestsError: false,
};

type GetRequestsAction = {
	type: typeof actionTypes.GET_REQUESTS;
};

type GetRequestsSuccessAction = {
	type: typeof actionTypes.GET_REQUESTS_SUCCESS;
};

type GetRequestsFailureAction = {
	type: typeof actionTypes.GET_REQUESTS_FAILURE;
};

type GetRequestsResetAction = {
	type: typeof actionTypes.GET_REQUESTS_RESET;
};

export type GetRequestsActionTypes =
	| GetRequestsAction
	| GetRequestsSuccessAction
	| GetRequestsFailureAction
	| GetRequestsResetAction;
