// Get Profile Data
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const GET_PROFILE_RESET = "GET_PROFILE_RESET";

// Edit Profile Data
export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";
export const EDIT_PROFILE_RESET = "EDIT_PROFILE_RESET";

// Verify auth
export const VERIFY_AUTH = "VERIFY_AUTH";
export const VERIFY_AUTH_SUCCESS = "VERIFY_AUTH_SUCCESS";
export const VERIFY_AUTH_FAILURE = "VERIFY_AUTH_FAILURE";
