import { call, put, takeLatest } from "redux-saga/effects";
import * as urls from "__utils/constants";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const getRequests = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getRequestsAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_REQUESTS_SUCCESS,
				data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_REQUESTS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REQUESTS_FAILURE });
	}
};

export const getRequestsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REQUESTS, getRequests);
};
