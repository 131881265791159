import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	addToWorkflowDefaultValue,
	getWorkflowHistoryDefaultValue,
	getWorkflowSummaryDefaultValue,
	getWorkflowUpdateDefaultValue,
	IAddToWorkflow,
	IAddToWorkflowActions,
	IGetWorkflowHistory,
	IGetWorkflowHistoryActions,
	IGetWorkflowSummary,
	IGetWorkflowSummaryActions,
	IGetWorkflowUpdate,
	IGetWorkflowUpdateActions,
	IMoveWorkflowState,
	IMoveWorkflowStateActions,
	moveWorkflowStateDefaultValue,
} from "./types";

const moveWorkflowStateReducer = (
	state = moveWorkflowStateDefaultValue,
	action: IMoveWorkflowStateActions
): IMoveWorkflowState => {
	switch (action.type) {
		case actionTypes.MOVE_WORKFLOW_STATE:
			return {
				...state,
				newState: action.payload.newState,
				movingWorkflowState: true,
			};
		case actionTypes.MOVE_WORKFLOW_STATE_SUCCESS:
			return {
				...state,
				movingWorkflowState: false,
				moveWorkflowStateSuccess: true,
				moveWorkflowStateFailure: false,
				moveWorkflowStateError: {},
			};
		case actionTypes.MOVE_WORKFLOW_STATE_FAILURE:
			return {
				...state,
				movingWorkflowState: false,
				moveWorkflowStateSuccess: false,
				moveWorkflowStateFailure: true,
				moveWorkflowStateError: {},
			};
		case actionTypes.MOVE_WORKFLOW_STATE_RESET:
			return {
				...state,
				...moveWorkflowStateDefaultValue,
			};
		default:
			return state;
	}
};

export const getWorkflowSummaryReducer = (
	state = getWorkflowSummaryDefaultValue,
	action: IGetWorkflowSummaryActions
): IGetWorkflowSummary => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOW_SUMMARY:
			return {
				...state,
				gettingWorkflowSummary: true,
			};
		case actionTypes.GET_WORKFLOW_SUMMARY_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingWorkflowSummary: false,
				getWorkflowSummarySuccess: true,
				getWorkflowSummaryFailure: false,
				getWorkflowSummaryError: {},
			};
		case actionTypes.GET_WORKFLOW_SUMMARY_FAILURE:
			return {
				...state,
				gettingWorkflowSummary: false,
				getWorkflowSummarySuccess: false,
				getWorkflowSummaryFailure: true,
				getWorkflowSummaryError: {},
			};
		case actionTypes.GET_WORKFLOW_SUMMARY_CANCEL:
		case actionTypes.GET_WORKFLOW_SUMMARY_RESET:
			return {
				...state,
				...getWorkflowSummaryDefaultValue,
			};
		default:
			return state;
	}
};

const getWorkflowUpdateReducer = (
	state = getWorkflowUpdateDefaultValue,
	action: IGetWorkflowUpdateActions
): IGetWorkflowUpdate => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOW_UPDATE:
			return {
				...state,
				gettingWorkflowUpdate: true,
			};
		case actionTypes.GET_WORKFLOW_UPDATE_SUCCESS:
			const { type, ...rest } = action;

			return {
				...state,
				...rest,
				gettingWorkflowUpdate: false,
				getWorkflowUpdateSuccess: true,
				getWorkflowUpdateFailure: false,
				getWorkflowUpdateError: {},
			};

		case actionTypes.GET_WORKFLOW_UPDATE_FAILURE:
			return {
				...state,
				gettingWorkflowUpdate: false,
				getWorkflowUpdateSuccess: false,
				getWorkflowUpdateFailure: true,
				getWorkflowUpdateError: {},
			};

		case actionTypes.GET_WORKFLOW_UPDATE_RESET:
			return {
				...state,
				...getWorkflowUpdateDefaultValue,
			};
		default:
			return state;
	}
};

export const addToWorkflowReducer = (
	state = addToWorkflowDefaultValue,
	action: IAddToWorkflowActions
): IAddToWorkflow => {
	switch (action.type) {
		case actionTypes.ADD_TO_WORKFLOW:
			return {
				...state,
				addingToWorkflow: true,
			};

		case actionTypes.ADD_TO_WORKFLOW_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				addingToWorkflow: false,
				addToWorkflowFailure: false,
				addToWorkflowSuccess: true,
			};

		case actionTypes.ADD_TO_WORKFLOW_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				addingToWorkflow: false,
				addToWorkflowFailure: true,
				addToWorkflowSuccess: false,
			};

		case actionTypes.ADD_TO_WORKFLOW_RESET:
			return {
				...state,
				...addToWorkflowDefaultValue,
			};

		default:
			return state;
	}
};

export const getWorkflowHistoryReducer = (
	state = getWorkflowHistoryDefaultValue,
	action: IGetWorkflowHistoryActions
): IGetWorkflowHistory => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOW_HISTORY:
			return {
				...state,
				gettingWorkflowHistory: true,
			};

		case actionTypes.GET_WORKFLOW_HISTORY_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				gettingWorkflowHistory: false,
				getWorkflowHistoryFailure: false,
				getWorkflowHistorySuccess: true,
			};

		case actionTypes.GET_WORKFLOW_HISTORY_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				gettingWorkflowHistory: false,
				getWorkflowHistoryFailure: true,
				getWorkflowHistorySuccess: false,
			};

		case actionTypes.GET_WORKFLOW_HISTORY_RESET:
			return {
				...state,
				...getWorkflowHistoryDefaultValue,
			};

		default:
			return state;
	}
};

export const workflowReducer = combineReducers({
	moveWorkflowState: moveWorkflowStateReducer,
	getWorkflowSummary: getWorkflowSummaryReducer,
	getWorkflowUpdate: getWorkflowUpdateReducer,
	addToWorkflow: addToWorkflowReducer,
	getWorkflowHistory: getWorkflowHistoryReducer,
});
