import * as actionTypes from "./actionTypes";

/**
 * Get Signing Details
 */

export type GetSigningDetailsType = {
	readonly docSignId: string;
	readonly isGettingSigningDetails: boolean;
	readonly isGetSigningDetailsSuccess: boolean;
	readonly isGetSigningDetailsError: boolean;
};

export const getSigningDetailsDefaultValue: GetSigningDetailsType = {
	docSignId: "",
	isGettingSigningDetails: false,
	isGetSigningDetailsSuccess: false,
	isGetSigningDetailsError: false,
};

export type GetSigningDetailsPayloadType = {
	id: number;
	envelopeId: string;
};

type GetSigningDetailsAction = {
	type: typeof actionTypes.GET_SIGNING_DETAILS;
	payload: GetSigningDetailsPayloadType;
};

type GetSigningDetailsSuccessAction = {
	type: typeof actionTypes.GET_SIGNING_DETAILS_SUCCESS;
};

type GetSigningDetailsFailureAction = {
	type: typeof actionTypes.GET_SIGNING_DETAILS_FAILURE;
};

type GetSigningDetailsResetAction = {
	type: typeof actionTypes.GET_SIGNING_DETAILS_RESET;
};

export type GetSigningDetailsActionTypes =
	| GetSigningDetailsAction
	| GetSigningDetailsSuccessAction
	| GetSigningDetailsFailureAction
	| GetSigningDetailsResetAction;
