// fetch docs data
export const FETCH_DOCUMENTS = "/document/";

// fetch member documents
export const FETCH_MEMBER_DOCUMENTS = "/member-documents/tree/";

// fetch member documents details
export const FETCH_MEMBER_DOCUMENTS_DETAILS = "/member-documents/tree/:key";

// fetch category list (including member specific catergories) for Uploads
export const FETCH_MEMBER_DOCS_CATEGORIES = "/member-documents/category/list/";

// upload a document
export const UPLOAD_MEMBER_DOCUMENT = "/member-documents/:id/add_member_document/";
