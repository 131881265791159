import React from "react";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import { useDispatch } from "react-redux";
import { editProfileCtr } from "__pages/Account/__redux/actions";
import { ProfileType } from "__pages/Account/__redux/types";
import "./style.scss";
import { tourState } from "./__utils/constants";

type Props = {
	onSetTourHighLight: (e: string) => void;
	onOpenCloseRightMenu: (e: boolean) => void;
	onSetTour: (e: boolean) => void;
	profileData: ProfileType;
	pathname: string;
};

const Tour: React.FC<Props> = ({
	onOpenCloseRightMenu,
	onSetTour,
	onSetTourHighLight,
	profileData,
	pathname,
}: Props) => {
	const dispatch = useDispatch();
	const [_tourState, setTourState] = React.useState<any>(tourState);
	// profile data
	const { isNew } = profileData;

	React.useEffect(() => {
		// Watch for profile data, and if user is new to the site, flag the tour to start
		if (isNew && pathname === "/") {
			// Run the tour
			setTourState({ ..._tourState, run: true });
		}
	}, [_tourState, isNew, pathname]);

	React.useEffect(() => {
		if (isNew) {
			// Only want to display the tour once
			const newProfiledata = { ...profileData, isNew: false };
			// Push the change
			dispatch(editProfileCtr(newProfiledata));
		}
	}, [isNew, dispatch, profileData]);

	const handleJoyrideCallback = (data: any): any => {
		const { action, index, type } = data;

		if (type === EVENTS.TOUR_START && _tourState.run) {
			setTimeout(() => {
				onSetTour(true);
			}, 850);
		} else if (type === EVENTS.TOUR_END && _tourState.run) {
			// End the tour
			setTimeout(() => {
				onSetTourHighLight("DASHBOARD");
				setTourState({ ..._tourState, run: false });
				onSetTour(false);
			}, 250);
		} else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 2) {
			setTourState({ ..._tourState, run: false, loading: true });
			setTimeout(() => {
				setTourState({
					..._tourState,
					loading: false,
					run: true,
					stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
				});
				onOpenCloseRightMenu(false);
			}, 850);
		} else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 3) {
			setTourState({ ..._tourState, run: false, loading: true });
			onOpenCloseRightMenu(true);

			setTimeout(() => {
				setTourState({
					..._tourState,
					loading: false,
					run: true,
					stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
				});
			}, 850);
		} else if (type === EVENTS.STEP_AFTER && index === 10) {
			// End the tour
			setTourState({ ..._tourState, run: false });
			setTimeout(() => {
				onSetTour(false);
			}, 850);

			// Remove routing to documents page as these are no longer autogenerated.
			// goToRoute('/documents')
		} else if (type === EVENTS.STEP_AFTER) {
			onSetTour(true);
			setTourState({ ..._tourState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		} else if (type === EVENTS.TOOLTIP_CLOSE) {
			setTourState({ ..._tourState, stepIndex: index + 1 });
		}

		if (index === 4) {
			onSetTourHighLight("DASHBOARD");
		} else if (index === 5) {
			onSetTourHighLight("REQUESTS");
		} else if (index === 6) {
			onSetTourHighLight("MY FUND");
		} else if (index === 7) {
			onSetTourHighLight("TRADING");
		} else if (index === 8) {
			onSetTourHighLight("DOCUMENTS");
		} else if (index === 9) {
			onSetTourHighLight("BILLING");
		} else {
			onSetTourHighLight("");
		}
	};

	const { ...joyrideProps } = _tourState;
	const tourProps = {
		...joyrideProps,
	};

	return <Joyride scrollToFirstStep showSkipButton {...tourProps} callback={handleJoyrideCallback} />;
};

export default Tour;
