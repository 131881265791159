// Portfolio
export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS";
export const GET_PORTFOLIO_FAILURE = "GET_PORTFOLIO_FAILURE";
export const GET_PORTFOLIO_RESET = "GET_PORTFOLIO_RESET";

// investment
export const GET_INVESTMENT = "GET_INVESTMENT";
export const GET_INVESTMENT_SUCCESS = "GET_INVESTMENT_SUCCESS";
export const GET_INVESTMENT_FAILURE = "GET_INVESTMENT_FAILURE";
export const GET_INVESTMENT_RESET = "GET_INVESTMENT_RESET";

// transactionReducers
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";
export const GET_TRANSACTION_RESET = "GET_TRANSACTION_RESET";
