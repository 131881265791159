import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import {
	GetRequestDetailPayloadType,
	RemoveDocumentPayloadType,
	SetDocumentToCompletePayloadType,
	SetRequestDetailToCompletePayloadType,
} from "../__redux/types";
import {
	FETCH_REQUESTS_DETAILS,
	REMOVE_DOCUMENT,
	SET_DOCUMENT_TO_COMPLETE,
	SET_REQUESTS_TO_COMPLETE,
} from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getRequestsDetailsAPI = async ({
	requestId,
}: GetRequestDetailPayloadType): Promise<AxiosResponse<any>> => {
	const url = FETCH_REQUESTS_DETAILS.replace(":requestId", `${requestId}`);

	return axios.get(url).catch(errorHandler);
};

export const setRequestDetailToCompleteAPI = async ({
	requestId,
}: SetRequestDetailToCompletePayloadType): Promise<AxiosResponse<any>> => {
	const url = SET_REQUESTS_TO_COMPLETE.replace(":requestId", `${requestId}`);

	return axios.put(url).catch(errorHandler);
};

export const setDocumentToCompleteAPI = async ({
	requestId,
	documentId,
}: SetDocumentToCompletePayloadType): Promise<AxiosResponse<any>> => {
	const url = SET_DOCUMENT_TO_COMPLETE.replace(":requestId", `${requestId}`).replace(":documentId", `${documentId}`);
	return axios.put(url).catch(errorHandler);
};

export const removeDocumentAPI = async ({ fileKey }: RemoveDocumentPayloadType): Promise<AxiosResponse<any>> => {
	const url = REMOVE_DOCUMENT.replace(":fileKey", `${fileKey}`);

	return axios.delete(url).catch(errorHandler);
};
