import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { ObjectType } from "__utils/types";
import { FETCH_INVESTMENT, FETCH_PORTFOLIO, FETCH_TRANSACTION } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getInvestmentAPI = async ({ todayDate }: ObjectType): Promise<AxiosResponse<any>> => {
	const url = FETCH_INVESTMENT.replace(":dateToday", `${todayDate}`);

	return axios.get(url).catch(errorHandler);
};

export const getPortfolioAPI = async ({ startDate, endDate }: ObjectType): Promise<AxiosResponse<any>> => {
	const url = FETCH_PORTFOLIO.replace(":startDate", `${startDate}`).replace(":endDate", `${endDate}`);

	return axios.get(url).catch(errorHandler);
};

export const getTransactionAPI = async ({ startDate, endDate }: ObjectType): Promise<AxiosResponse<any>> => {
	const url = FETCH_TRANSACTION.replace(":startDate", `${startDate}`).replace(":endDate", `${endDate}`);

	return axios.get(url).catch(errorHandler);
};
