import cx from "classnames";
import React from "react";
import "./style.scss";

type Props = {
	num: number;
	show: boolean;
	title?: string;
	darkMode?: boolean;
};

const SimpleLoader: React.FC<Props> = ({ show, title, darkMode = false, num }: Props) => {
	return (
		<>
			{show && (
				<div className={cx("simple-loader", { "simple-loader--dark-mode": darkMode })}>
					{title && <div className="simple-loader__title">{title}</div>}
					{[...new Array(num)].map((d, index) => (
						<div key={index} className="simple-loader__section">
							<div className="animated-bg animated-bg--short" />
							<div className="animated-bg animated-bg--long" />
							<div className="animated-bg animated-bg--long" />
							<div className="animated-bg animated-bg--long" />
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default SimpleLoader;
