import message from "antd/es/message";
import cx from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import routes from "routes";
import Footer from "__common/Footer";
import PageNotFound from "__common/PageNotFound";
import NotificationPanel from "__layout/NotificationPanel/";
import { getNotificationCtr } from "__layout/NotificationPanel/__redux/actions";
import Overlay from "__layout/Overlay/";
import SideBar from "__layout/SideBar/";
import TopBar from "__layout/TopBar";
import Tour from "__layout/Tour/";
import TourDemo from "__layout/TourDemo/";
import { getProfileCtr, verifyAuthCtr } from "__pages/Account/__redux/actions";
import { getProfileSlr, verifyAuthSlr } from "__pages/Account/__redux/selectors";
import { IVerifyAuth } from "__pages/Account/__redux/types";
import { BREAKPOINTS } from "__utils/constants";
import { AntOverridesStyle } from "__assets/css/ant_global";
import { layoutTypes, RouteType } from "./__utils/types";

const layoutInitValue: layoutTypes = {
	openLeftMenu: false,
	openRightMenu: false,
	openCongratsInfo: true,
	onTour: false,
	onTourHighlight: "",
};

const DEMO_EMAIL = "demo+1865@selfmade.com.au";

// Global config form toast message
message.config({ top: 0 });

type Props = unknown;

const App: React.FC<Props> = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { pathname } = location;
	const isLessThanMD = useMediaQuery({ query: BREAKPOINTS.LESS_THAN_MD });
	const isGreaterThanLG = useMediaQuery({ query: BREAKPOINTS.GREATER_THAN_LG });
	const [isDemo, setIsDemo] = React.useState<boolean>(false);

	const { data: profileData } = useSelector(getProfileSlr);
	const { email, isNew } = profileData;
	const [layoutProps, setLayoutProps] = React.useState(layoutInitValue);
	const { openLeftMenu, openRightMenu } = layoutProps;

	const { verifyAuthSuccess }: IVerifyAuth = useSelector(verifyAuthSlr);

	React.useEffect(() => {
		dispatch(verifyAuthCtr({ referer: window.location.href }));
	}, [dispatch]);

	React.useEffect(() => {
		if (verifyAuthSuccess) {
			dispatch(getProfileCtr());
			dispatch(getNotificationCtr());
		}
	}, [verifyAuthSuccess, dispatch]);

	// member-area classes based on the truthy value 'openRightMenu'
	const dynClass = cx("main", { "right-menu-open": openRightMenu });

	React.useEffect(() => {
		if (isGreaterThanLG && pathname === "/") {
			if (email === DEMO_EMAIL && !isDemo) {
				setIsDemo(true);
				setLayoutProps({ ...layoutProps, openRightMenu: true });
			} else if (isNew) {
				setLayoutProps({ ...layoutProps, openRightMenu: true });
			}
		}
	}, [isGreaterThanLG, email, isNew, layoutProps, pathname, isDemo]);

	const onBurgerMenuClick = (): void => {
		setLayoutProps({ ...layoutProps, openLeftMenu: !openLeftMenu });
	};

	const onCloseClickOverLay = (): void => {
		setLayoutProps({ ...layoutProps, openLeftMenu: false, openRightMenu: false });
	};

	const onClickLeftMenu = (): void => {
		setLayoutProps({ ...layoutProps, openLeftMenu: false });
	};

	const onOpenCloseRightMenu = (val: boolean): void => {
		setLayoutProps({ ...layoutProps, openRightMenu: val });
	};

	const onCloseCongratsInfoBox = (): void => {
		setLayoutProps({ ...layoutProps, openCongratsInfo: false });
	};

	const onSetTour = (val: boolean): void => {
		setLayoutProps({ ...layoutProps, onTour: val });
	};

	const onSetTourHighLight = (val: string): void => {
		setLayoutProps({ ...layoutProps, onTourHighlight: val });
	};

	return (
		<Router>
			<AntOverridesStyle />
			<div className={dynClass}>
				{/* top bar component */}
				<TopBar onBurgerMenuClick={onBurgerMenuClick} />

				{/* body element that contains the overlay, menus & footer */}
				<div className="page">
					{/* overlay component (only show on tablet and mobile) */}
					{isLessThanMD && <Overlay onCloseClickOverLay={onCloseClickOverLay} LayoutProps={layoutProps} />}

					{/* routing happens only in the main area */}
					<div className="content">
						<React.Suspense fallback={null}>
							<Switch>
								{routes.map((route: RouteType) => {
									const { component: Component, ...rest } = route;
									return (
										<Route {...rest}>
											<Component />
										</Route>
									);
								})}
								<Route>
									<PageNotFound />
								</Route>
							</Switch>
						</React.Suspense>
					</div>

					{/* left menu component */}
					<SideBar onClickLeftMenu={onClickLeftMenu} LayoutProps={layoutProps} />

					{/* right menu component */}
					<NotificationPanel
						LayoutProps={layoutProps}
						onOpenCloseRightMenu={onOpenCloseRightMenu}
						onCloseCongratsInfoBox={onCloseCongratsInfoBox}
					/>

					{/* footer component (only show on tablet) */}
					{isLessThanMD && <Footer />}
				</div>

				{/* Tour component (only in desktop) */}
				{isGreaterThanLG && pathname === "/" && email !== DEMO_EMAIL && (
					<Tour
						onOpenCloseRightMenu={onOpenCloseRightMenu}
						onSetTour={onSetTour}
						onSetTourHighLight={onSetTourHighLight}
						profileData={profileData}
						pathname={pathname}
					/>
				)}

				{/* Tour component (only in desktop) for DEMO account */}
				{isGreaterThanLG && pathname === "/" && email === DEMO_EMAIL && (
					<TourDemo
						onOpenCloseRightMenu={onOpenCloseRightMenu}
						onSetTour={onSetTour}
						onSetTourHighLight={onSetTourHighLight}
						isDemo={isDemo}
						pathname={pathname}
					/>
				)}
			</div>
		</Router>
	);
};

export default App;
