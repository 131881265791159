import cx from "classnames";
import React from "react";
import { layoutTypes } from "__utils/types";
import "./style.scss";

// style import

type Props = {
	onCloseClickOverLay: () => void;
	LayoutProps: layoutTypes;
};

const Overlay: React.FC<Props> = ({ onCloseClickOverLay, LayoutProps }: Props) => {
	const { openLeftMenu, openRightMenu } = LayoutProps;

	const dynClass = cx("overlay", { "overlay--show": openLeftMenu || openRightMenu });

	return <div className={dynClass} onClick={() => onCloseClickOverLay} />;
};

export default Overlay;
