import { call, put, select, takeLatest } from "redux-saga/effects";
import * as urls from "__utils/constants";
import { ResponseGeneratorType } from "__utils/types";
import * as requestsDocsActionTypes from "../../DocumentRequests/__redux/actionTypes";
import { getRequestSlr } from "../../DocumentRequests/__redux/selectors";
import { GetRequestsType, RequestsType } from "../../DocumentRequests/__redux/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const getSigningDetails = function* (action: any): any {
	const requestDetails: GetRequestsType = yield select(getRequestSlr);
	const { id, envelopeId } = action.payload;

	try {
		const response: ResponseGeneratorType = yield call(api.getSigningRequestsAPI, envelopeId);

		const { status, data: signingDetails } = response;

		if (status === 200) {
			const { data: _data } = requestDetails;
			const updateSigningData = _data.map((data: RequestsType) => {
				if (data.id === id) {
					return { ...data, signingRequestsDetails: signingDetails };
				}
				return { ...data };
			});

			yield put({
				type: actionTypes.GET_SIGNING_DETAILS_SUCCESS,
			});
			yield put({
				type: requestsDocsActionTypes.GET_REQUESTS_SUCCESS,
				data: updateSigningData,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_SIGNING_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_SIGNING_DETAILS_FAILURE });
	}
};

export const getSigningDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_SIGNING_DETAILS, getSigningDetails);
};
