import { ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";

export type GetDocumentsType = {
	readonly envelopeId: string;
	readonly isGettingDocuments: boolean;
	readonly isGetDocumentsSuccess: boolean;
	readonly isGetDocumentsError: boolean;
};

export const getdocumentsDefaultValue: GetDocumentsType = {
	envelopeId: "",
	isGettingDocuments: false,
	isGetDocumentsSuccess: false,
	isGetDocumentsError: false,
};

export type GetDocumentPayloadType = {
	envelopeId: string;
};

type GetDocumentsAction = {
	type: typeof actionTypes.GET_DOCUMENTS;
	payload: GetDocumentPayloadType;
};

type GetDocumentsSuccessAction = {
	type: typeof actionTypes.GET_DOCUMENTS_SUCCESS;
};

type GetDocumentsFailureAction = {
	type: typeof actionTypes.GET_DOCUMENTS_FAILURE;
};

type GetDocumentsResetAction = {
	type: typeof actionTypes.GET_DOCUMENTS_RESET;
};

export type GetDocumentsActionTypes =
	| GetDocumentsAction
	| GetDocumentsSuccessAction
	| GetDocumentsFailureAction
	| GetDocumentsResetAction;

/**
 * Get MemberDocDetails
 */

export interface DocSignStatusType {
	readonly envelopeStatus: string;
	readonly primaryRecipientStatus: string;
	readonly statusText: string;
	readonly status: number;
}

export interface DocumentTreeType {
	readonly id?: number;
	readonly type: string;
	readonly name: string;
	readonly envelopeId: string;
	readonly dateCreated: string;
	readonly files: Array<ObjectType>;
	readonly key?: string;
	readonly createdDate?: string;
	readonly category?: string;
	readonly uri?: string;
	readonly status?: DocSignStatusType;
}

export interface MemberDocDetailsType {
	readonly documentTree: Array<DocumentTreeType>;
	readonly memberDocFolderLookup: ObjectType;
}

export type GetMemberDocumentsType = {
	readonly data: MemberDocDetailsType;
	readonly isGettingMemberDocDetails: boolean;
	readonly isGetMemberDocumentsSuccess: boolean;
	readonly isGetMemberDocumentsError: boolean;
};

export const getMemberDocumentsDefaultValue: GetMemberDocumentsType = {
	data: {
		documentTree: [],
		memberDocFolderLookup: {},
	},
	isGettingMemberDocDetails: false,
	isGetMemberDocumentsSuccess: false,
	isGetMemberDocumentsError: false,
};

type GetMemberDocumentsAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENTS;
};

type GetMemberDocumentsSuccessAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENTS_SUCCESS;
};

type GetMemberDocumentsFailureAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENTS_FAILURE;
};

type GetMemberDocumentsResetAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENTS_RESET;
};

export type GetMemberDocumentsActionTypes =
	| GetMemberDocumentsAction
	| GetMemberDocumentsSuccessAction
	| GetMemberDocumentsFailureAction
	| GetMemberDocumentsResetAction;

/**
 * Get Member Document Details
 */

export type GetMemberDocumentDetailsType = {
	readonly key: string;
	readonly isGettingMemberDocumentDetails: boolean;
	readonly isGetMemberDocumentDetailsSuccess: boolean;
	readonly isGetMemberDocumentDetailsError: boolean;
};

export const getMemberDocumentDetailsDefaultValue: GetMemberDocumentDetailsType = {
	key: "",
	isGettingMemberDocumentDetails: false,
	isGetMemberDocumentDetailsSuccess: false,
	isGetMemberDocumentDetailsError: false,
};

export type GetDocumentDetailsPayloadType = {
	key: string;
};

type GetMemberDocumentDetailsAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENT_DETAILS;
	payload: GetDocumentDetailsPayloadType;
};

type GetMemberDocumentDetailsSuccessAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENT_DETAILS_SUCCESS;
};

type GetMemberDocumentDetailsFailureAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENT_DETAILS_FAILURE;
};

type GetMemberDocumentDetailsResetAction = {
	type: typeof actionTypes.GET_MEMBER_DOCUMENT_DETAILS_RESET;
};

export type GetMemberDocumentDetailsActionTypes =
	| GetMemberDocumentDetailsAction
	| GetMemberDocumentDetailsSuccessAction
	| GetMemberDocumentDetailsFailureAction
	| GetMemberDocumentDetailsResetAction;

/**
 * Get MemberDocCategories
 */

export interface MemberDocCategoriesType {
	readonly value: number;
	readonly label: string;
}

export type GetMemberDocCategoriesType = {
	readonly data: Array<MemberDocCategoriesType>;
	readonly isGettingMemberDocCategories: boolean;
	readonly isGettingMemberDocCategoriesSuccess: boolean;
	readonly isGettingMemberDocCategoriesError: boolean;
};

export const getMemberDocCategoriesDefaultValue: GetMemberDocCategoriesType = {
	data: [],
	isGettingMemberDocCategories: false,
	isGettingMemberDocCategoriesSuccess: false,
	isGettingMemberDocCategoriesError: false,
};

type GetMemberDocCategoriesAction = {
	type: typeof actionTypes.GET_MEMBER_DOC_CATEGORIES;
};

type GetMemberDocCategoriesSuccessAction = {
	type: typeof actionTypes.GET_MEMBER_DOC_CATEGORIES_SUCCESS;
};

type GetMemberDocCategoriesFailureAction = {
	type: typeof actionTypes.GET_MEMBER_DOC_CATEGORIES_FAILURE;
};

type GetMemberDocCategoriesResetAction = {
	type: typeof actionTypes.GET_MEMBER_DOC_CATEGORIES_RESET;
};

export type GetMemberDocCategoriesActionTypes =
	| GetMemberDocCategoriesAction
	| GetMemberDocCategoriesSuccessAction
	| GetMemberDocCategoriesFailureAction
	| GetMemberDocCategoriesResetAction;

/**
 * UPLOAD DOCUMENT
 */

export type UploadDocumentType = {
	readonly isUploadingDocument: boolean;
	readonly isUploadDocumentSuccess: boolean;
	readonly isUploadDocumentError: boolean;
};

export const uploadDocumentDefaultValue: UploadDocumentType = {
	isUploadingDocument: false,
	isUploadDocumentSuccess: false,
	isUploadDocumentError: false,
};

export type UploadDocumentPayloadType = {
	memberId: number;
	formData: ObjectType;
};

type UploadDocumentAction = {
	type: typeof actionTypes.UPLOAD_DOCUMENT;
	payload: UploadDocumentPayloadType;
};

type UploadDocumentSuccessAction = {
	type: typeof actionTypes.UPLOAD_DOCUMENT_SUCCESS;
};

type UploadDocumentFailureAction = {
	type: typeof actionTypes.UPLOAD_DOCUMENT_FAILURE;
};

type UploadDocumentResetAction = {
	type: typeof actionTypes.UPLOAD_DOCUMENT_RESET;
};

export type UploadDocumentActionTypes =
	| UploadDocumentAction
	| UploadDocumentSuccessAction
	| UploadDocumentFailureAction
	| UploadDocumentResetAction;
