import { call, put, takeEvery } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const getNotifications = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getNotificationAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_NOTIFICATION_SUCCESS,
				notifications: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_NOTIFICATION_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_NOTIFICATION_FAILURE });
	}
};

// NOTIFICATION
export const getNotificationsSaga = function* (): any {
	yield takeEvery(actionTypes.GET_NOTIFICATION, getNotifications);
};

const sendReferral = function* (action: any): any {
	const { payload } = action;

	try {
		const response: ResponseGeneratorType = yield call(api.sendReferralAPI, payload);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.SEND_REFERRAL_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.SEND_REFERRAL_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.SEND_REFERRAL_FAILURE });
	}
};
// SEND REFERRAL
export const sendReferralSaga = function* (): any {
	yield takeEvery(actionTypes.SEND_REFERRAL, sendReferral);
};
