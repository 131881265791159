import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProfileSlr } from "../__redux/selectors";
import { GetProfileType, ProfileType } from "../__redux/types";
import "./style.scss";

type Props = unknown;

const Avatar: React.FC<Props> = () => {
	const { data: profileData }: GetProfileType = useSelector(getProfileSlr);
	const { firstName }: ProfileType = profileData;
	const [name, setName] = React.useState<string>("");
	const history = useHistory();

	const onClickUpdateProfile = (e: any): void => {
		// do not refresh page
		e.preventDefault();
		e.stopPropagation();
		// go to route
		history.push("/account");
	};

	React.useEffect(() => {
		setName(firstName);
	}, [firstName]);

	return (
		<div>
			<div className="avatar">
				{/* container for the avatar icon, name & link */}
				<div className="avatar__info-container">
					<div className="avatar__info-container__profile-container">
						<p className="avatar__info-container__avatar-name"> {name} </p>
						<a
							href="/"
							className="avatar__info-container__profile-container__update"
							onClick={onClickUpdateProfile}
						>
							Update my profile
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Avatar;
