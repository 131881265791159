import { createGlobalStyle } from "styled-components";

export const AntOverridesStyle = createGlobalStyle`
		.ant-switch-checked {
			background-color: #4638d0;
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: #4638d0;
			border-color: #4638d0;
		}
		.ant-radio-inner::after {
			background-color: #4638d0;
		}

		.ant-card-head-wrapper {
			align-items: start;
		}
`;
