import React from "react";
import { TourType } from "__utils/types";

export const tourState: TourType = {
	continuous: true,
	loading: false,
	run: false,
	spotlightPadding: 0,
	styles: {
		options: {
			primaryColor: "#31259F",
			textColor: "#000",
			overlayColor: "rgba(0, 0, 0, 0.65)",
		},
		buttonSkip: {
			fontSize: 14,
		},
		buttonClose: {
			display: "none",
		},
		tooltip: {
			padding: 25,
			borderRadius: 0,
		},
		tooltipTitle: {
			margin: "0",
		},
		tooltipContent: {
			padding: "10px 0px",
			fontSize: 14,
			textAlign: "left",
		},
		tooltipFooter: {
			marginTop: 10,
		},
		buttonNext: {
			padding: "8px 16px",
			fontSize: 14,
			fontWeight: "bold",
			lineHeight: "21px",
		},
		buttonBack: {
			fontSize: 14,
		},
	},
	steps: [
		{
			title: <div className="tour__main-title">Welcome to Selfmade!</div>,
			content: (
				<div>
					<div className="tour__main-desc">
						Our platform makes it easy and quick to setup and look after your SMSF. Let’s take a short tour
						through the site and then you can look around at your leisure.
					</div>
				</div>
			),
			placement: "center",
			disableBeacon: true,
			styles: {
				options: {
					width: 600,
					zIndex: 10000,
				},
			},
			locale: { skip: "Skip", next: "Take a Quick Tour" },
			target: "body",
		},
		// {
		//     title: <div className="tour__main-title">Pending Actions</div>,
		//     content: <div>This is where we will keep you updated on any actions required on your SMSF.
		//                 It could be rolling over your existing super, setting up employer contributions,
		//                 reviewing and signing off tax statements, or updating your SMSF’s investment strategy.
		//                 Whatever it is, we have you covered.</div>,
		//     placement: "left",
		//     disableBeacon: true,
		//     styles: {
		//         options: {
		//             width: 500
		//         }
		//     },
		//     target: ".right-menu__middle-container",
		//     spotlightPadding: 3,
		// },
		{
			title: <div className="tour__main-title">Notifications Panel</div>,
			content: (
				<div>
					You can minimise the notifications panel here. If you have any outstanding actions that need your
					attention, the panel will be open when you next log in.
				</div>
			),
			placement: "left",
			disableBeacon: true,
			styles: {
				options: {
					width: 515,
				},
			},
			target: ".right-menu-container__close-container",
			spotlightPadding: 3,
		},

		{
			title: <div className="tour__main-title">Workflow</div>,
			content: (
				<div>
					The workflow section shows you at what stage your fund is up to in any given workflow, such as
					on-boarding or end of financial year tax lodgements.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".workflow-card-header span",
		},
		{
			title: <div className="tour__main-title">Dashboard</div>,
			content: (
				<div>
					The dashboard gives you a snapshot of your SMSF including your fund&#x27;s current investment
					holdings, the fund value, a list of recent transactions as well as the workflow overview.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".dashboard_target",
		},
		{
			title: <div className="tour__main-title">Requests</div>,
			content: <div>You can review your outstanding document and signing requests here.</div>,
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".requests_target",
		},
		{
			title: <div className="tour__main-title">My Fund</div>,
			content: (
				<div>
					This section displays all the important details of your SMSF including details of trustees and
					members, registered addresses, the fund name and any other registration details.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".myfund_target",
		},
		{
			title: <div className="tour__main-title">Trading</div>,
			content: (
				<div>
					For new SMSFs established through Selfmade, clients will receive a Macquarie CMA and online trading
					account to get you started investing. If you have an existing SMSF that you want to transfer onto
					Selfmade, you can keep your existing cash and trading accounts.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".trading_target",
		},
		{
			title: <div className="tour__main-title">Documents</div>,
			content: (
				<div>
					You can access your SMSF&#x27;s documents here at any time - including any uploaded and signed
					documents provided via requests.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".documents_target",
		},
		{
			title: <div className="tour__main-title">Billing</div>,
			content: <div>You can review your fund&#x27;s invoices here at any time.</div>,
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".billing_target",
		},

		{
			title: <div className="tour__main-title">Take a look around</div>,
			content: (
				<div className="tour__main-footer">
					You’re all set. If you want more information, check out our FAQ’s or get in contact. If you’re ready
					to get started, register your account with Selfmade and take control of your super today.
				</div>
			),
			placement: "center",
			disableBeacon: true,
			styles: {
				options: {
					width: 646,
					zIndex: 10000,
				},
			},
			locale: { back: "", skip: "Skip", last: "Done" },
			target: "body",
		},
	],
	stepIndex: 0,
};
