import * as actionTypes from "./actionTypes";

/**
 * Get Portfolio
 */

export interface HistoryType {
	readonly id: number;
	readonly dateCreated: string;
	readonly fundValue: number;
	readonly classAccount: number;
}

export interface PortfolioType {
	readonly currentValue: number;
	readonly history: Array<HistoryType>;
}

export type GetPortfolioType = {
	readonly data: PortfolioType;
	readonly isGettingPortfolio: boolean;
	readonly isGetPortfolioSuccess: boolean;
	readonly isGetPortfolioError: boolean;
};

export const getPortfolioDefaultValue: GetPortfolioType = {
	data: {
		currentValue: 0,
		history: [],
	},
	isGettingPortfolio: false,
	isGetPortfolioSuccess: false,
	isGetPortfolioError: false,
};

type GetPortfolioAction = {
	type: typeof actionTypes.GET_PORTFOLIO;
};

type GetPortfolioSuccessAction = {
	type: typeof actionTypes.GET_PORTFOLIO_SUCCESS;
};

type GetPortfolioFailureAction = {
	type: typeof actionTypes.GET_PORTFOLIO_FAILURE;
};

type GetPortfolioResetAction = {
	type: typeof actionTypes.GET_PORTFOLIO_RESET;
};

export type GetPortfolioActionTypes =
	| GetPortfolioAction
	| GetPortfolioSuccessAction
	| GetPortfolioFailureAction
	| GetPortfolioResetAction;

/**
 * Get Investment
 */

export interface InvestmentType {
	readonly assetClassId: string;
	readonly assetClassName: string;
	readonly valueOfAssetsHeld: number;
	readonly marketValueOfAssetsHeld: number;
	readonly unsettledPositionOnAssetsHeld: number;
	readonly assetStrategyMinPercent: number;
	readonly assetStrategyMaxPercent: number;
	readonly assetStrategyOverallPercent: number;
}

export type GetInvestmentType = {
	readonly data: Array<InvestmentType>;
	readonly isGettingInvestment: boolean;
	readonly isGetInvestmentSuccess: boolean;
	readonly isGetInvestmentError: boolean;
};

export const getInvestmentDefaultValue: GetInvestmentType = {
	data: [],
	isGettingInvestment: false,
	isGetInvestmentSuccess: false,
	isGetInvestmentError: false,
};

type GetInvestmentAction = {
	type: typeof actionTypes.GET_INVESTMENT;
};

type GetInvestmentSuccessAction = {
	type: typeof actionTypes.GET_INVESTMENT_SUCCESS;
};

type GetInvestmentFailureAction = {
	type: typeof actionTypes.GET_INVESTMENT_FAILURE;
};

type GetInvestmentResetAction = {
	type: typeof actionTypes.GET_INVESTMENT_RESET;
};

export type GetInvestmentActionTypes =
	| GetInvestmentAction
	| GetInvestmentSuccessAction
	| GetInvestmentFailureAction
	| GetInvestmentResetAction;

/**
 * Get Transaction
 */

export interface TransactionsType {
	readonly date: string;
	readonly amount: number;
	readonly direction: string;
	readonly description: string;
	readonly index: number;
	readonly depositAmount: number;
	readonly withdrawalAmount: number;
	readonly runningTotal: number;
	readonly cashIn: number;
	readonly cashOut: number;
}

export interface TransactionDetailsType {
	readonly openingBalance: number;
	readonly closingBalance: number;
	readonly openingBalanceDate: string;
	readonly currencyCode: string;
	readonly accountBsb: string;
	readonly accountNumber: string;
	readonly accountCode: string;
	readonly accountDescription: string;
	readonly status: string;
	readonly transactions: Array<TransactionsType>;
}

export interface TransactionType {
	readonly tData: Array<TransactionDetailsType>;
	readonly closingBalance: number;
}

export type GetTransactionType = {
	readonly data: TransactionType;
	readonly isGettingTransaction: boolean;
	readonly isGetTransactionSuccess: boolean;
	readonly isGetTransactionError: boolean;
};

export const transactionDefaultValue: TransactionDetailsType = {
	openingBalance: 0,
	closingBalance: 0,
	openingBalanceDate: "",
	currencyCode: "",
	accountBsb: "",
	accountNumber: "",
	accountCode: "",
	accountDescription: "",
	status: "",
	transactions: [],
};

export const getTransactionDefaultValue: GetTransactionType = {
	data: {
		tData: [],
		closingBalance: 0,
	},
	isGettingTransaction: false,
	isGetTransactionSuccess: false,
	isGetTransactionError: false,
};

export type GetTransactionPayloadType = {
	startDate: string;
	endDate: string;
};

type GetTransactionAction = {
	type: typeof actionTypes.GET_TRANSACTION;
	payload: GetTransactionPayloadType;
};

type GetTransactionSuccessAction = {
	type: typeof actionTypes.GET_TRANSACTION_SUCCESS;
};

type GetTransactionFailureAction = {
	type: typeof actionTypes.GET_TRANSACTION_FAILURE;
};

type GetTransactionResetAction = {
	type: typeof actionTypes.GET_TRANSACTION_RESET;
};

export type GetTransactionActionTypes =
	| GetTransactionAction
	| GetTransactionSuccessAction
	| GetTransactionFailureAction
	| GetTransactionResetAction;
