import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { GetSigningDetailsPayloadType } from "../__redux/types";
import { FETCH_SIGNING_REQUESTS } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getSigningRequestsAPI = async (envelopeId: GetSigningDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url = FETCH_SIGNING_REQUESTS.replace(":envelopeId", `${envelopeId}/`);

	return axios.get(url).catch(errorHandler);
};
