import { combineReducers } from "redux";
import { notificationReducer } from "__layout/NotificationPanel/__redux/reducers";
import { accountReducer } from "__pages/Account/__redux/reducers";
import { billingReducer } from "__pages/Billing/__redux/reducers";
import { dashboardReducer } from "__pages/Dashboard/__redux/reducers";
import { documentsReducer } from "__pages/Documents/__redux/reducers";
import { fundReducer } from "__pages/Fund/__redux/reducers";
import { requestsReducer } from "__pages/Requests/DocumentRequests/__redux/reducers";
import { workflowReducer } from "__common/Workflow/__redux/reducers";

// add all reducers here to match the initState
const rootReducer = combineReducers({
	documents: documentsReducer,
	dashboard: dashboardReducer,
	account: accountReducer,
	requests: requestsReducer,
	fund: fundReducer,
	billing: billingReducer,
	notification: notificationReducer,
	workflow: workflowReducer,
});
export default rootReducer;
