import {
	GetRequestDetailType,
	RemoveDocumentType,
	SetDocumentToCompleteType,
	SetRequestDetailToCompleteType,
} from "./types";

export const requestDetailSlr = (state: any): GetRequestDetailType => state.requests.requestsDetails.requestDetailState;
export const requestToCompleteSlr = (state: any): SetRequestDetailToCompleteType =>
	state.requests.requestsDetails.requestToCompleteState;
export const documentToCompleteSlr = (state: any): SetDocumentToCompleteType =>
	state.requests.requestsDetails.documentToCompleteState;
export const removeDocFileSlr = (state: any): RemoveDocumentType => state.requests.requestsDetails.removeDocFileState;
