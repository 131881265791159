// GET NOTIFICATION
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";
export const GET_NOTIFICATION_RESET = "GET_NOTIFICATION_RESET";

// SEND REFERRAL
export const SEND_REFERRAL = "SEND_REFERRAL";
export const SEND_REFERRAL_SUCCESS = "SEND_REFERRAL_SUCCESS";
export const SEND_REFERRAL_FAILURE = "SEND_REFERRAL_FAILURE";
export const SEND_REFERRAL_RESET = "SEND_REFERRAL_RESET";
