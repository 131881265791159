import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import ArrowIcon from "__assets/svg/arrow_blue.svg";
import AvatarIcon from "__assets/svg/avatar.svg";
import LockIcon from "__assets/svg/lock.svg";
import Logo from "__assets/svg/selfmade_logo.svg";
import AppLink from "__common/AppLink";
import menuItems from "./menuItems";
import "./style.scss";
import TopBarMenuItem from "./TopBarMenuItem";

type Props = {
	onBurgerMenuClick: () => void;
};

const TopBar: React.FC<Props> = ({ onBurgerMenuClick }: Props) => {
	const location = useLocation();
	const { pathname } = location;

	return (
		<div className="top-bar">
			{/* selfmade superfund logo */}
			{/* Logo need to be clickable back to dashboard */}
			<AppLink route={menuItems[5].route}>
				<img src={Logo} className="top-bar__logo" alt="Selfmade superfund logo" />
			</AppLink>

			{/* topbar menu items and icons */}
			<div className="top-bar__menu-items">
				<TopBarMenuItem menuItem={menuItems[0]} pathname={pathname} />

				<TopBarMenuItem menuItem={menuItems[1]} pathname={pathname} />

				<TopBarMenuItem menuItem={menuItems[2]} pathname={pathname} />

				<div className="top-bar__menu-items__icon-wrapper-left">
					<TopBarMenuItem
						menuItem={menuItems[3]}
						pathname={pathname}
						leftIcon={AvatarIcon}
						leftIconClass="top-bar__menu-items__icon-wrapper-left__acc"
						leftIconAlt="Avatar icon"
						rightIcon={ArrowIcon}
						rightIconClass="top-bar__menu-items__icon-wrapper-left__arrow"
						rightIconAlt="Arrow icon"
					/>

					<TopBarMenuItem
						menuItem={menuItems[4]}
						pathname={pathname}
						leftIcon={LockIcon}
						leftIconClass="top-bar__menu-items__icon-wrapper-left__lock"
						leftIconAlt="Lock icon"
					/>
				</div>
			</div>

			{/* hamburger icon */}
			<GiHamburgerMenu className="top-bar__hamburger" onClick={onBurgerMenuClick} />
		</div>
	);
};

export default TopBar;
