import React from "react";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import "./style.scss";
import { tourState } from "./__utils/constants";

type Props = {
	onSetTourHighLight: (e: string) => void;
	onOpenCloseRightMenu: (e: boolean) => void;
	onSetTour: (e: boolean) => void;
	isDemo: boolean;
	pathname: string;
};

const TourDemo: React.FC<Props> = ({
	onOpenCloseRightMenu,
	onSetTour,
	onSetTourHighLight,
	isDemo,
	pathname,
}: Props) => {
	const [_tourState, setTourState] = React.useState<any>(tourState);

	React.useEffect(() => {
		// Watch for profile data, and if user is new to the site, flag the tour to start
		if (!isDemo && pathname === "/") {
			// Run the tour
			setTourState({ ..._tourState, run: true });
		}
	}, [_tourState, isDemo, pathname]);

	const handleJoyrideCallback = (data: any): void => {
		const { action, index, type } = data;

		if (type === EVENTS.TOUR_START && _tourState.run) {
			setTimeout(() => {
				onSetTour(true);
			}, 850);
		} else if (type === EVENTS.TOUR_END && _tourState.run) {
			// End the tour
			setTimeout(() => {
				onSetTourHighLight("DASHBOARD");
				setTourState({ ..._tourState, run: false });
				onSetTour(false);
			}, 250);
		} else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 1) {
			setTourState({ ..._tourState, run: false, loading: true });

			setTimeout(() => {
				setTourState({
					..._tourState,
					loading: false,
					run: true,
					stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
				});
				onOpenCloseRightMenu(false);
			}, 850);
		} else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV && index === 3) {
			setTourState({ ..._tourState, run: false, loading: true });
			onOpenCloseRightMenu(true);
			setTimeout(() => {
				setTourState({
					..._tourState,
					loading: false,
					run: true,
					stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
				});
			}, 850);
		} else if (type === EVENTS.STEP_AFTER && index === 9) {
			// End the tour
			setTourState({ ..._tourState, run: false });
			setTimeout(() => {
				onOpenCloseRightMenu(false);
				onSetTour(false);
			}, 850);
		} else if (type === EVENTS.STEP_AFTER) {
			setTourState({ ..._tourState, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
		} else if (type === EVENTS.TOOLTIP_CLOSE) {
			setTourState({ ..._tourState, stepIndex: index + 1 });
		}

		if (index === 3) {
			onSetTourHighLight("DASHBOARD");
		} else if (index === 4) {
			onSetTourHighLight("REQUESTS");
		} else if (index === 5) {
			onSetTourHighLight("MY FUND");
		} else if (index === 6) {
			onSetTourHighLight("TRADING");
		} else if (index === 7) {
			onSetTourHighLight("DOCUMENTS");
		} else if (index === 8) {
			onSetTourHighLight("BILLING");
		} else {
			onSetTourHighLight("");
		}
	};

	const { ...joyrideProps } = _tourState;
	const _props = {
		...joyrideProps,
	};

	return <Joyride scrollToFirstStep showSkipButton {..._props} callback={handleJoyrideCallback} />;
};

export default TourDemo;
