export const MOVE_WORKFLOW_STATE = "MOVE_WORKFLOW_STATE";
export const MOVE_WORKFLOW_STATE_SUCCESS = "MOVE_WORKFLOW_STATE_SUCCESS";
export const MOVE_WORKFLOW_STATE_FAILURE = "MOVE_WORKFLOW_STATE_FAILURE";
export const MOVE_WORKFLOW_STATE_RESET = "MOVE_WORKFLOW_STATE_RESET";

export const GET_WORKFLOW_SUMMARY = "GET_WORKFLOW_SUMMARY";
export const GET_WORKFLOW_SUMMARY_SUCCESS = "GET_WORKFLOW_SUMMARY_SUCCESS";
export const GET_WORKFLOW_SUMMARY_FAILURE = "GET_WORKFLOW_SUMMARY_FAILURE";
export const GET_WORKFLOW_SUMMARY_RESET = "GET_WORKFLOW_SUMMARY_RESET";
export const GET_WORKFLOW_SUMMARY_CANCEL = "GET_WORKFLOW_SUMMARY_CANCEL";

export const GET_WORKFLOW_UPDATE = "GET_WORKFLOW_UPDATE";
export const GET_WORKFLOW_UPDATE_SUCCESS = "GET_WORKFLOW_UPDATE_SUCCESS";
export const GET_WORKFLOW_UPDATE_FAILURE = "GET_WORKFLOW_UPDATE_FAILURE";
export const GET_WORKFLOW_UPDATE_RESET = "GET_WORKFLOW_UPDATE_RESET";

export const ADD_TO_WORKFLOW = "ADD_TO_WORKFLOW";
export const ADD_TO_WORKFLOW_SUCCESS = "ADD_TO_WORKFLOW_SUCCESS";
export const ADD_TO_WORKFLOW_FAILURE = "ADD_TO_WORKFLOW_FAILURE";
export const ADD_TO_WORKFLOW_RESET = "ADD_TO_WORKFLOW_RESET";

export const GET_WORKFLOW_HISTORY = "GET_WORKFLOW_HISTORY";
export const GET_WORKFLOW_HISTORY_SUCCESS = "GET_WORKFLOW_HISTORY_SUCCESS";
export const GET_WORKFLOW_HISTORY_FAILURE = "GET_WORKFLOW_HISTORY_FAILURE";
export const GET_WORKFLOW_HISTORY_RESET = "GET_WORKFLOW_HISTORY_RESET";
