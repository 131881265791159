import * as actionTypes from "./actionTypes";
import { GetFundActionTypes, getFundDefaultValue, GetFundType } from "./types";

// FUND REDUCERS
export const fundReducer = (state = getFundDefaultValue, action: GetFundActionTypes): GetFundType => {
	switch (action.type) {
		case actionTypes.GET_FUND:
			return {
				...state,
				isGettingFund: true,
			};
		case actionTypes.GET_FUND_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingFund: false,
				isGetFundSuccess: true,
				isGetFundError: false,
			};

		case actionTypes.GET_FUND_FAILURE:
			return {
				...state,
				isGettingFund: false,
				isGetFundSuccess: false,
				isGetFundError: true,
			};

		case actionTypes.GET_FUND_RESET:
			return {
				...getFundDefaultValue,
			};
		default:
			return state;
	}
};
