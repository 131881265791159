import * as actionTypes from "./actionTypes";
import { GetBillingActionTypes, getBillingDefaultValue, GetBillingType } from "./types";

export const billingReducer = (state = getBillingDefaultValue, action: GetBillingActionTypes): GetBillingType => {
	switch (action.type) {
		case actionTypes.GET_BILLING:
			return {
				...state,
				isGettingBilling: true,
			};
		case actionTypes.GET_BILLING_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingBilling: false,
				isGetBillingSuccess: true,
				isGetBillingError: false,
			};

		case actionTypes.GET_BILLING_FAILURE:
			return {
				...state,
				isGettingBilling: false,
				isGetBillingSuccess: false,
				isGetBillingError: true,
			};

		case actionTypes.GET_BILLING_RESET:
			return {
				...getBillingDefaultValue,
			};
		default:
			return state;
	}
};
