import { isEmpty } from "lodash";
import moment from "moment";
import { call, put, takeEvery } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const getInvestment = function* (): any {
	const todayDate = moment().format("YYYY-MM-DD");

	try {
		const response: ResponseGeneratorType = yield call(api.getInvestmentAPI, { todayDate });
		const { status, data } = response;

		if (status === 200) {
			if (!isEmpty(data) && data.data.portfolioAssetPositions[0].overallValue > 0) {
				// get the array of transaction objects
				const bankAccountDetails = response.data.data.portfolioAssetPositions;

				yield put({
					type: actionTypes.GET_INVESTMENT_SUCCESS,
					data: bankAccountDetails[0].assetClassDetailList,
				});
			} else {
				yield put({
					type: actionTypes.GET_INVESTMENT_SUCCESS,
					data: [],
				});
			}
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_INVESTMENT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_INVESTMENT_FAILURE });
	}
};

// INVESTMENT
export const getInvestmentSaga = function* (): any {
	yield takeEvery(actionTypes.GET_INVESTMENT, getInvestment);
};

const getPortfolio = function* (): any {
	const startDate = moment().subtract(6, "months").format("YYYY-MM-DD");
	const endDate = moment().format("YYYY-MM-DD");

	try {
		const response: ResponseGeneratorType = yield call(api.getPortfolioAPI, { startDate, endDate });

		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_PORTFOLIO_SUCCESS,
				data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_PORTFOLIO_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_PORTFOLIO_FAILURE });
	}
};

// PORTFOLIO
export const getPortfolioSaga = function* (): any {
	yield takeEvery(actionTypes.GET_PORTFOLIO, getPortfolio);
};

export const getTransaction = function* ({ payload }: any): any {
	// Date Range Payload
	const { startDate: sDate, endDate: eDate } = payload;

	const startDate = moment(sDate).format("YYYY-MM-DD");
	const endDate = moment(eDate).format("YYYY-MM-DD");

	try {
		// get the response promise object
		const response: ResponseGeneratorType = yield call(api.getTransactionAPI, { startDate, endDate });
		const { status, data } = response; // data

		if (status === 200) {
			// get the array of transaction objects
			// const bankAccountDetails = tDataFixtures; //data.data;

			yield put({
				type: actionTypes.GET_TRANSACTION_SUCCESS,
				data: {
					tData: data.data,
					closingBalance: 0, // parseFloat(bankAccountDetails.closingBalance, 10 )
				},
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_TRANSACTION_FAILURE });
		}
	} catch (error) {
		// set the success flag to false
		yield put({ type: actionTypes.GET_TRANSACTION_FAILURE });
	}
};

// TRANSACTION
export const getTransactionSaga = function* (): any {
	yield takeEvery(actionTypes.GET_TRANSACTION, getTransaction);
};
