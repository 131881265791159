import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { API_ROOT, endpoints } from "./constants";
import { ObjectType } from "./types";

/**
 * Parses body data returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed data, status from the response
 */

/**
 * Redirect user to sign-in page when user is unauthorised
 *
 * @param  {object} response A response from a network request
 * @return {object}          [description]
 */
export const redirectUnauthorised = (response: ObjectType): any => {
	const { status, url } = response;
	const regex = new RegExp(`^${API_ROOT}`);

	// When our django api return 403 PermissionDenied or Unauthorised
	// we will redirect the user to sign-in page
	if (status === 403 && regex.test(url)) {
		window.location.href = endpoints.websiteSignInURL;
	}

	return response;
};

// // mock the API call delay
// export const wait = (ms :any) => new Promise((resolve) => setTimeout(resolve, ms));

/*
 * this function converts a number into a international currency format
 * i.e. 23456 becomes $23,456
 * @param - locale (STRING) - i.e. 'en-US', 'en-GB', 'en-AU'
 * @param - style (STRING) - i.e. 'currency'
 * @param - currency (STRING) - i.e. 'USD', 'AUD'
 * @param - number (STRING or INTEGER) - the number to be formatted
 */
export const intlNumberFormatter = (locale: string, style: string, currency: string, number: number): string => {
	const localeString = locale || "en-US";
	// create a Intl.NumberFormat object
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
	const formatter = new Intl.NumberFormat(localeString, {
		style: style || "currency",
		currency: currency || "AUD",
		minimumFractionDigits: 2,
	});
	// format and return the currency formatted value
	return formatter.format(number);
};

/**
 * String format util.
 * Use this to substitute string {varaible} with object that has matching
 * key value pairs
 *
 * Example of usage
 * substitute('/api/notifications/{id}/dismiss', {id: '1'})
 * returned: /api/notifications/1/dismiss
 *
 * @param  {str} str e.g /api/notifications/{id}/dismiss
 * @param  {obj} obj An object contain key:value for subsitution
 * @return {str}     substituted str
 */
export const substitute = (str: string, obj: ObjectType): string => {
	return str.replace(/{[^}]+}/g, (match) => {
		const _match = match.replace(/[{}]/g, "");
		return obj[_match] === undefined ? match : obj[_match];
	});
};

/**
 * Strips HTML tags from given string
 * @param s
 * @returns
 */
export const stripHtmlTags = (s: string): string => {
	const regex = /&(nbsp|amp|quot|lt|gt);/g;
	return s
		.replace(/(<([^>]+)>)/gi, " ")
		.replace(/\s{2,}/gi, " ")
		.replace(regex, " ")
		.trim();
};
/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
export const outerHeight = (element: any): number => {
	if (!element) return 0;

	const height = element.offsetHeight;
	const style = window.getComputedStyle(element) as { [key: string]: any };

	return ["top", "bottom"]
		.map((side) => parseFloat(style[`margin-${side}`]))
		.reduce((total, side) => total + side, height);
};

/**
 * Returns the element width including padding etc
 * @param element - element
 * @returns {number}
 */
export const outerWidth = (element: any): number => {
	if (!element) return 0;

	const width = element.offsetWidth;
	const style = window.getComputedStyle(element) as { [key: string]: any };

	return ["left", "right"]
		.map((side) => parseInt(style[`margin-${side}`]))
		.reduce((total, side) => total + side, width);
};

/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
export const dateIsDue = (dueDate: string): boolean => {
	const _dueDate = new Date(moment(dueDate).format("YYYY-MM-DD"));
	const dateNow: any = Date.now();

	if (dateNow >= _dueDate) {
		return true;
	}

	return false;
};

/**
 * Returns global axios instance
 * @param apiBaseUrl
 */
export const getAxiosInstance = (apiBaseUrl: string, contentType = "application/json"): AxiosInstance => {
	const axiosInstanceName = `${apiBaseUrl}_axiosInstance`;
	const instance: AxiosInstance = ((apiBaseUrl: string) => {
		return axios.create({
			baseURL: apiBaseUrl,
			withCredentials: true,
			headers: {
				"X-CSRFToken": Cookies.get("csrftoken"),
				"Content-Type": contentType,
			},
		});
	})(apiBaseUrl);

	if ((window as any)[axiosInstanceName]) {
		return (window as any)[axiosInstanceName];
	}

	(window as any)[axiosInstanceName] = instance;

	return instance;
};

/**
 * Handles API errors
 * @param error
 */
export const errorHandler = (error: any): any => {
	if (process.env.NODE_ENV === "development") {
		console.warn(error.config);
	}
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		if (process.env.NODE_ENV === "development") {
			console.warn(error.response.data);
			console.warn(error.response.status);
			console.warn(error.response.headers);
		}
		return error.response;
	}
	if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		if (process.env.NODE_ENV === "development") {
			console.warn(error.request);
		}
		return {
			data: { error: "Something went wrong." },
		};
	}
	// Something happened in setting up the request that triggered an Error
	if (process.env.NODE_ENV === "development") {
		console.warn("Error", error.message);
	}
	return {
		data: { error: "Something went wrong." },
	};
};

export const formatAbn = (abn: any): string => {
	if (!abn) return "";

	const _abn: any = `${abn}`;
	const abnLen: any = [..._abn].length;
	const firstChunk = _abn.substring(0, 2);
	const restChunk: any = _abn.substring(2, abnLen);
	const targetChunks = Math.floor([...restChunk].length / 3);
	let chunks = "";
	let count = 0;

	[...restChunk].forEach((char: string) => {
		const chunksLen = chunks.split(" ").length;
		count++;

		if (count % 3 === 0 && chunksLen <= targetChunks) {
			chunks = `${chunks}${char} `;
		} else {
			chunks = `${chunks}${char}`;
		}
	});

	return `${firstChunk} ${chunks}`;
};

export const formatAcn = (acn: any): string => {
	if (!acn) return "";

	const _acn: any = `${acn}`;
	const targetChunks = Math.floor([..._acn].length / 3);
	let chunks = "";
	let count = 0;

	[..._acn].forEach((char: string) => {
		const chunksLen = chunks.split(" ").length;
		count++;

		if (count % 3 === 0 && chunksLen <= targetChunks) {
			chunks = `${chunks}${char} `;
		} else {
			chunks = `${chunks}${char}`;
		}
	});

	return chunks;
};

export const formatMobile = (mobile: any): string => {
	if (!mobile) return "";

	const _mobile: any = `${mobile}`;
	const mobileLen: any = [..._mobile].length;
	const firstChunk = _mobile.substring(0, 4);
	const restChunk: any = _mobile.substring(4, mobileLen);
	const targetChunks = Math.floor([...restChunk].length / 3);
	let chunks = "";
	let count = 0;

	[...restChunk].forEach((char: string) => {
		const chunksLen = chunks.split(" ").length;
		count++;

		if (count % 3 === 0 && chunksLen <= targetChunks) {
			chunks = `${chunks}${char} `;
		} else {
			chunks = `${chunks}${char}`;
		}
	});

	return `${firstChunk} ${chunks}`;
};

export const isNumeric = (value: string): boolean => {
	return /^-?\d+$/.test(value);
};
