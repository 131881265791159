import {
	GetDocumentsType,
	GetMemberDocCategoriesType,
	GetMemberDocumentsType,
	UploadDocumentType,
	GetMemberDocumentDetailsType,
} from "./types";

export const documentsSlr = (state: any): GetDocumentsType => state.documents.documents;
export const memberDocumentsSlr = (state: any): GetMemberDocumentsType => state.documents.memberDocuments;
export const memberDocumentDetailsSlr = (state: any): GetMemberDocumentDetailsType =>
	state.documents.memberDocumentDetails;
export const memberDocCategoriesSlr = (state: any): GetMemberDocCategoriesType => state.documents.memberDocCategories;
export const uploadDocumentSlr = (state: any): UploadDocumentType => state.documents.uploadDocument;
