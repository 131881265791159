import cx from "classnames";
import React from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import AppButton from "__common/AppButton";
import AppLink from "__common/AppLink";
import Footer from "__common/Footer";
import Avatar from "__pages/Account/Avatar";
import { isEqual } from "lodash";
import { requestsSlr } from "__pages/Requests/DocumentRequests/__redux/selectors";
import { RequestsType } from "__pages/Requests/DocumentRequests/__redux/types";
import { BREAKPOINTS, endpoints } from "__utils/constants";
import { layoutTypes } from "__utils/types";
import topbarMenuItems from "../TopBar/menuItems";
import MenuItem from "./MenuItem";
import menuItems from "./menuItems";
import "./style.scss";

type Props = {
	onClickLeftMenu: () => void;
	LayoutProps: layoutTypes;
};

const SideBar: React.FC<Props> = ({ onClickLeftMenu, LayoutProps }: Props) => {
	const { openLeftMenu, onTour, onTourHighlight } = LayoutProps;
	const location = useLocation();
	const { pathname } = location;
	const LESS_THAN_LG = useMediaQuery({ query: BREAKPOINTS.LESS_THAN_LG });
	const LESS_THAN_MD = useMediaQuery({ query: BREAKPOINTS.LESS_THAN_MD });
	const GREATER_THAN_MD = useMediaQuery({ query: BREAKPOINTS.GREATER_THAN_MD });
	const requestsData: RequestsType[] = useSelector(requestsSlr, isEqual);

	const onMenuClick = (): void => {
		// close the left menu on clicking menu item in mobile and tablet
		onClickLeftMenu();
	};

	const signOutClick = (): void => {
		// close the left menu on clicking sign out button in mobile and tablet
		if (LESS_THAN_LG) onClickLeftMenu();
	};

	const dynClass = cx("menu left-menu", { "left-menu--open": openLeftMenu });

	return (
		<div className={dynClass}>
			<div className="left-menu__wrapper">
				{/* close icon (only tablet and mobile view) */}
				<FaRegWindowClose className="left-menu__close-icon" onClick={() => onClickLeftMenu()} />

				{/* avatar component */}
				<Avatar />

				{/* creating menu items from array */}
				<div className="left-menu__menu-items">
					{menuItems.map((item, index) => {
						if (requestsData.length === 1 && item.menuText === "REQUESTS") {
							const requestsPath =
								requestsData[0].type === "document" ? `/request/${requestsData[0].id}` : "/requests/";
							return (
								<MenuItem
									key={index}
									svgIcon={item.menuIcon}
									isExternal={item.isExternal}
									openInNewTab={item.openInNewTab}
									menuText={item.menuText}
									route={requestsPath}
									pathname={pathname}
									onClick={() => onMenuClick()}
									tourId={item.tourId}
									onTour={onTour}
									onTourHighlight={onTourHighlight}
									requestsData={requestsData}
								/>
							);
						}

						return (
							<MenuItem
								key={index}
								svgIcon={item.menuIcon}
								isExternal={item.isExternal}
								openInNewTab={item.openInNewTab}
								menuText={item.menuText}
								route={item.route}
								pathname={pathname}
								onClick={() => onMenuClick()}
								tourId={item.tourId}
								onTour={onTour}
								onTourHighlight={onTourHighlight}
								requestsData={requestsData}
							/>
						);
					})}
				</div>

				{/* creating top menu items from array (only show on table and mobile) */}
				{LESS_THAN_MD && (
					<div className="left-menu__top-menu-items">
						{topbarMenuItems.map((item, index) =>
							// don't display the sign-out item (index 4)
							index < 4 ? (
								<MenuItem
									key={index}
									svgIcon={item.menuIcon}
									isExternal={item.isExternal}
									openInNewTab={item.openInNewTab}
									menuText={item.menuText}
									route={item.route}
									pathname={pathname}
									requestsData={requestsData}
								/>
							) : null
						)}

						{/* sign out button */}
						<div className="left-menu__sign-out-button">
							<AppLink isExternal route={endpoints.websiteSignOutURL}>
								<AppButton onClick={() => signOutClick} type="primary" size="large">
									SIGN OUT
								</AppButton>
							</AppLink>
						</div>
					</div>
				)}

				{/* include footer (only show on desktop) */}
				{GREATER_THAN_MD && <Footer />}
			</div>
		</div>
	);
};

export default SideBar;
