import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { GetDocumentPayloadType, UploadDocumentPayloadType, GetDocumentDetailsPayloadType } from "../__redux/types";
import {
	FETCH_DOCUMENTS,
	FETCH_MEMBER_DOCS_CATEGORIES,
	FETCH_MEMBER_DOCUMENTS,
	UPLOAD_MEMBER_DOCUMENT,
	FETCH_MEMBER_DOCUMENTS_DETAILS,
} from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getDocumentsAPI = async (envelopeId: GetDocumentPayloadType): Promise<AxiosResponse<any>> => {
	return axios.get(`${FETCH_DOCUMENTS}${envelopeId}/`).catch(errorHandler);
};

export const getMemberDocumentsAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(FETCH_MEMBER_DOCUMENTS).catch(errorHandler);
};

export const getMemberDocumentDetailsAPI = async (key: GetDocumentDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url = FETCH_MEMBER_DOCUMENTS_DETAILS.replace(":key", `${key}`);

	return axios.get(url).catch(errorHandler);
};

export const getMemberDocsCategoryAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(FETCH_MEMBER_DOCS_CATEGORIES).catch(errorHandler);
};

export const uploadDocumentApi = async ({
	memberId,
	formData,
}: UploadDocumentPayloadType): Promise<AxiosResponse<any>> => {
	const url = UPLOAD_MEMBER_DOCUMENT.replace(":id", `${memberId}`);
	return axios.post(url, formData).catch(errorHandler);
};
