import cx from "classnames";
import React from "react";
import { FaTasks } from "react-icons/fa";
import AppLink from "__common/AppLink";
import "./style.scss";
import { AiFillExclamationCircle } from "react-icons/ai";
import Badge from "antd/es/badge";
import { RequestsType } from "__pages/Requests/DocumentRequests/__redux/types";
import { isEmpty } from "lodash";

const getAlertType = (requestsData: RequestsType[]): string | null => {
	if (isEmpty(requestsData)) return null;

	const result = requestsData.filter((e: RequestsType) => e.alert === "Overdue");
	if (result.length !== 0) {
		return "Overdue";
	}

	return "Outstanding";
};

type Props = {
	svgIcon: React.ReactNode;
	isExternal: boolean;
	menuText: string;
	route: string;
	onClick?: () => void;
	pathname: string;
	openInNewTab?: boolean;
	tourId?: string;
	onTour?: boolean;
	onTourHighlight?: string;
	requestsData: RequestsType[];
};

const MenuItem: React.FC<Props> = ({
	svgIcon,
	isExternal,
	openInNewTab,
	menuText,
	route,
	onClick,
	pathname,
	tourId,
	onTour,
	onTourHighlight,
	requestsData,
}: Props) => {
	const leftIconAltText = `Menu item left icon for ${menuText}`;
	// USE TO DETERMINE IF YOUR IN REQUEST VIEW AND HIGHLIGHT IT
	const _pathame = pathname.includes("request");
	const alertType = getAlertType(requestsData);
	const alertClassName = cx("menu-item__menu-text-alert", {
		"menu-item__menu-text-alert--red": alertType === "Overdue",
		"menu-item__menu-text-alert--yellow": alertType === "Outstanding",
	});

	const dynClass = cx(
		"menu-item",
		{
			"menu-item--active":
				(route === pathname && onTour === false) ||
				(onTour === true && onTourHighlight === menuText) ||
				(menuText === "REQUESTS" && _pathame),
		},
		tourId
	);

	const menuTextToRender = (): React.ReactNode => {
		if (menuText === "REQUESTS" && alertType !== null) {
			return (
				<span className="menu-item__menu-text">
					<Badge offset={[9, -4]} count={<AiFillExclamationCircle />} className={alertClassName}>
						{menuText}
					</Badge>
				</span>
			);
		}
		return <span className="menu-item__menu-text">{menuText} </span>;
	};

	return (
		<AppLink
			isExternal={isExternal}
			onClick={onClick}
			route={route}
			openInNewTab={openInNewTab}
			className={dynClass}
		>
			<span className="menu-item__innerwrapper">
				{svgIcon ? (
					<img src={svgIcon as string} className="menu-item__left-icon" alt={leftIconAltText} />
				) : (
					<FaTasks className="menu-item__left-icon request-icon" />
				)}

				{menuTextToRender()}
				{/* Removing the external link indicator from main menu items: isExternal ?
						<div className="menu-item__right-icon-container">
								<img src={LinkIcon} className="menu-item__right-icon-container__right-icon-external"
											alt="Avatar icon"/>
						</div>
						:
						<img src={ArrowIcon} className="menu-item__right-icon" alt="Arrow icon"/>
				*/}
			</span>
		</AppLink>
	);
};

export default MenuItem;
