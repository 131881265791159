import { call, put, select, takeLatest } from "redux-saga/effects";
import * as urls from "__utils/constants";
import { ResponseGeneratorType, ObjectType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { fundSlr } from "./selectors";
import { MembersTrusteeInfoDataType } from "./types";

// get the title of the member/truess
const getMemberTrusteeTitle = (index: number, fundData: any): string => {
	const fundType = fundData.fundType === "INDIVIDUAL" ? "Trustee" : "Director";

	switch (index) {
		// Trustee 2 is the special case.
		// When fundData['trustees'] is not empty, meaning the Trustee 2 is not a member
		case 1:
			if (fundData.trustees.length === 0) {
				return `${fundType} ${index + 1}`; // `Member & ${fundType} ${index + 1}`;
			}
			return `${fundType} ${index + 1}`;

		default:
			return `${fundType} ${index + 1}`; // `Member & ${fundType} ${index + 1}`;
	}
};

// get refined data of member truste
const getMemberTrusteeData = (fundData: any): Array<MembersTrusteeInfoDataType> => {
	const allMemberTrustee = fundData.members.concat(fundData.trustees);
	const fundMembersTrusteesData = allMemberTrustee.map((ele: any, index: number) => {
		return {
			heading: getMemberTrusteeTitle(index, fundData),
			data: ele,
		};
	});

	return fundMembersTrusteesData;
};

const getFund = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getFundAPI);
		const { status, data } = response;

		if (status === 200) {
			const {
				fundName,
				fundType,
				jurisdiction,
				investmentDetails,
				companyDetails,
				inClass,
				abn,
				tfn,
			}: ObjectType = data;

			const fundInfoData = {
				fundName,
				fundType,
				jurisdiction,
				accountName: investmentDetails ? investmentDetails.accountName : "",
				accountNumber: investmentDetails ? investmentDetails.accountNumber : "",
				bsb: investmentDetails ? investmentDetails.bsb : "",
				abn: abn || "ABN registration in progress",
				tfn,
				inClass,
			};

			const membersTrusteesInfoData = getMemberTrusteeData(data);

			const companyDetailsData = {
				companyName: companyDetails ? companyDetails.companyName : "",
				acn: companyDetails ? companyDetails.acn : "",
			};
			yield put({
				type: actionTypes.GET_FUND_SUCCESS,
				data: {
					fundInfoData,
					membersTrusteesInfoData,
					companyDetailsData,
				},
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_FUND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_FUND_FAILURE });
	}
};

export const getFundSaga = function* (): any {
	yield takeLatest(actionTypes.GET_FUND, getFund);
};

const getBasicFund = function* (): any {
	const { data: fundsData } = yield select(fundSlr);

	try {
		const response: ResponseGeneratorType = yield call(api.getBasicFundAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_FUND_SUCCESS,
				data: {
					...fundsData,
					basicFundInfoData: data,
				},
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_FUND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_FUND_FAILURE });
	}
};

export const getBasicFundSaga = function* (): any {
	yield takeLatest(actionTypes.GET_BASIC_FUND, getBasicFund);
};
