// fetch url for the list of Investment
export const FETCH_INVESTMENT = "/accounting/asset_position/:dateToday/";

// url for Investment download
export const DOWNLOAD_INVESTMENT = "/accounting/investment_summary/download/";

// url for Investment download
export const DOWNLOAD_ASSET_ALLOCATION = "/accounting/asset_position/download/";

// fetch url for the list of transactions
export const FETCH_TRANSACTION = "/accounting/transaction_details/:startDate/:endDate/";

// fetch url for the list of Portfolio value
export const FETCH_PORTFOLIO = "/accounting/fund_value/:startDate/:endDate/";
