import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";
import {
	GetNotificationActionTypes,
	getNotificationDefaultValue,
	GetNotificationType,
	SendReferralActionTypes,
	SendReferralDefaultValue,
	SendReferralType,
} from "./types";

export const _notificationReducer = (
	state = getNotificationDefaultValue,
	action: GetNotificationActionTypes
): GetNotificationType => {
	switch (action.type) {
		case actionTypes.GET_NOTIFICATION:
			return {
				...state,
				isGettingNotification: true,
			};
		case actionTypes.GET_NOTIFICATION_SUCCESS:
			const { type: successType, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingNotification: false,
				isGetNotificationSuccess: true,
				isGetNotificationError: false,
			};
		case actionTypes.GET_NOTIFICATION_FAILURE:
			return {
				...state,
				isGettingNotification: false,
				isGetNotificationSuccess: false,
				isGetNotificationError: true,
			};

		case actionTypes.GET_NOTIFICATION_RESET:
			return {
				...getNotificationDefaultValue,
			};

		default:
			return state;
	}
};

export const referralReducer = (
	state = SendReferralDefaultValue,
	action: SendReferralActionTypes
): SendReferralType => {
	switch (action.type) {
		case actionTypes.SEND_REFERRAL:
			return {
				...state,
				isSendingRefferal: true,
			};
		case actionTypes.SEND_REFERRAL_SUCCESS:
			return {
				isSendingRefferal: false,
				isSendReferralSuccess: true,
				isSendReferralError: false,
			};
		case actionTypes.SEND_REFERRAL_FAILURE:
			return {
				...state,
				isSendingRefferal: false,
				isSendReferralSuccess: false,
				isSendReferralError: true,
			};

		case actionTypes.SEND_REFERRAL_RESET:
			return {
				...SendReferralDefaultValue,
			};

		default:
			return state;
	}
};
export const notificationReducer = combineReducers({
	referral: referralReducer,
	notifications: _notificationReducer,
});
