import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { SendReferralPayloadType } from "../__redux/types";
import { FETCH_NOTIFICATION, SEND_REFERRAL_EMAIL } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getNotificationAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(FETCH_NOTIFICATION).catch(errorHandler);
};

export const sendReferralAPI = async (payload: SendReferralPayloadType): Promise<AxiosResponse<any>> => {
	const { id, email } = payload;
	const url = SEND_REFERRAL_EMAIL.replace(":id", `${id}`);
	return axios.post(url, email).catch(errorHandler);
};
