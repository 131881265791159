import cx from "classnames";
import React from "react";
import AppLink from "__common/AppLink";
import { ObjectType } from "__utils/types";
import "./style.scss";

type Props = {
	menuItem: ObjectType;
	pathname: string;
	onClick?: () => void;
	leftIcon?: string | undefined;
	leftIconClass?: string;
	leftIconAlt?: string;
	rightIcon?: string | undefined;
	rightIconClass?: string;
	rightIconAlt?: string;
};

const TopBarMenuItem: React.FC<Props> = ({
	rightIcon,
	rightIconAlt,
	rightIconClass,
	menuItem,
	pathname,
	leftIcon,
	leftIconAlt,
	leftIconClass,
	onClick,
}: Props) => {
	const { route, isExternal, openInNewTab, menuText } = menuItem;

	// check of prop menuText exist
	const _menuText = menuItem ? menuText : null;

	// check if current path equals the menuItem.route and if yes set CSS active
	const dynClass = cx("top-bar-menu-item", { "top-bar-menu-item--active": route === pathname });

	return (
		<AppLink
			isExternal={isExternal}
			onClick={onClick}
			route={menuItem.route}
			openInNewTab={openInNewTab}
			className={dynClass}
		>
			<span>
				{leftIcon && <img src={leftIcon} className={leftIconClass} alt={leftIconAlt} />}

				<span className="top-bar-menu-item__text">{_menuText}</span>
				{rightIcon && <img src={rightIcon} className={rightIconClass} alt={rightIconAlt} />}
			</span>
		</AppLink>
	);
};

export default TopBarMenuItem;
