import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { FETCH_BILLING } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getBillingAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(FETCH_BILLING).catch(errorHandler);
};
