// GET DOCUMENT
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE";
export const GET_DOCUMENTS_RESET = "GET_DOCUMENTS_RESET";

// GET MEMBER DOCUMENTS
export const GET_MEMBER_DOCUMENTS = "GET_MEMBER_DOCUMENTS";
export const GET_MEMBER_DOCUMENTS_SUCCESS = "GET_MEMBER_DOCUMENTS_SUCCESS";
export const GET_MEMBER_DOCUMENTS_FAILURE = "GET_MEMBER_DOCUMENTS_FAILURE";
export const GET_MEMBER_DOCUMENTS_RESET = "GET_MEMBER_DOCUMENTS_RESET";

// GET MEMBER DOCUMENTS DETAILS
export const GET_MEMBER_DOCUMENT_DETAILS = "GET_MEMBER_DOCUMENT_DETAILS";
export const GET_MEMBER_DOCUMENT_DETAILS_SUCCESS = "GET_MEMBER_DOCUMENT_DETAILS_SUCCESS";
export const GET_MEMBER_DOCUMENT_DETAILS_FAILURE = "GET_MEMBER_DOCUMENT_DETAILS_FAILURE";
export const GET_MEMBER_DOCUMENT_DETAILS_RESET = "GET_MEMBER_DOCUMENT_DETAILS_RESET";

// GET MEMBER DOC CATEGORIES
export const GET_MEMBER_DOC_CATEGORIES = "GET_MEMBER_DOC_CATEGORIES";
export const GET_MEMBER_DOC_CATEGORIES_SUCCESS = "GET_MEMBER_DOC_CATEGORIES_SUCCESS";
export const GET_MEMBER_DOC_CATEGORIES_FAILURE = "GET_MEMBER_DOC_CATEGORIES_FAILURE";
export const GET_MEMBER_DOC_CATEGORIES_RESET = "GET_MEMBER_DOC_CATEGORIES_RESET";

// UPLOAD DOCUMENT
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAILURE = "UPLOAD_DOCUMENT_FAILURE";
export const UPLOAD_DOCUMENT_RESET = "UPLOAD_DOCUMENT_RESET";
