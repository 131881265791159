import * as actionTypes from "./actionTypes";

/**
 * Get RequestDetail
 */

export interface FilesType {
	readonly fileName: string;
	readonly originalFileName: string;
	readonly key: string;
	readonly uploadDate: string;
	readonly dueDate?: string;
}

export interface DocumentsType {
	readonly id: number;
	readonly documentId: number;
	readonly title: string;
	readonly category: string;
	readonly information: string;
	readonly description: string;
	readonly activity: boolean;
	readonly isComplete: boolean;
	readonly availabilityDate: string;
	readonly difficulty: number;
	readonly files: Array<FilesType>;
}

export interface RequestDetailType {
	readonly id: number;
	readonly title: string;
	readonly state: string;
	readonly movements: Array<string>;
	readonly description: string;
	readonly dueDate: string;
	readonly updatedDate: string;
	readonly documents: Array<DocumentsType>;
	readonly reminders: Array<string>;
}

export type GetRequestDetailType = {
	readonly data: RequestDetailType;
	readonly isGettingRequestDetail: boolean;
	readonly isGetRequestDetailSuccess: boolean;
	readonly isGetRequestDetailError: boolean;
};

export const getRequestDetailDefaultValue: GetRequestDetailType = {
	data: {
		id: 0,
		title: "",
		state: "",
		movements: [],
		description: "",
		dueDate: "",
		updatedDate: "",
		documents: [],
		reminders: [],
	},
	isGettingRequestDetail: false,
	isGetRequestDetailSuccess: false,
	isGetRequestDetailError: false,
};

export type GetRequestDetailPayloadType = {
	requestId: number;
};

type GetRequestDetailAction = {
	type: typeof actionTypes.GET_REQUEST_DETAIL;
	payload: GetRequestDetailPayloadType;
};

type GetRequestDetailSuccessAction = {
	type: typeof actionTypes.GET_REQUEST_DETAIL_SUCCESS;
};

type GetRequestDetailFailureAction = {
	type: typeof actionTypes.GET_REQUEST_DETAIL_FAILURE;
};

type GetRequestDetailResetAction = {
	type: typeof actionTypes.GET_REQUEST_DETAIL_RESET;
};

export type GetRequestDetailActionTypes =
	| GetRequestDetailAction
	| GetRequestDetailSuccessAction
	| GetRequestDetailFailureAction
	| GetRequestDetailResetAction;

/**
 * SetRequestDetailToComplete
 */

export type SetRequestDetailToCompleteType = {
	readonly isSettingRequestDetailToComplete: boolean;
	readonly isSetRequestDetailToCompleteSuccess: boolean;
	readonly isSetRequestDetailToCompleteError: boolean;
};

export const setRequestDetailToCompleteDefaultValue: SetRequestDetailToCompleteType = {
	isSettingRequestDetailToComplete: false,
	isSetRequestDetailToCompleteSuccess: false,
	isSetRequestDetailToCompleteError: false,
};

export type SetRequestDetailToCompletePayloadType = {
	requestId: number;
};

type SetRequestDetailToCompleteAction = {
	type: typeof actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE;
	payload: SetRequestDetailToCompletePayloadType;
};

type SetRequestDetailToCompleteSuccessAction = {
	type: typeof actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_SUCCESS;
};

type SetRequestDetailToCompleteFailureAction = {
	type: typeof actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_FAILURE;
};

type SetRequestDetailToCompleteResetAction = {
	type: typeof actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_RESET;
};

export type SetRequestDetailToCompleteActionTypes =
	| SetRequestDetailToCompleteAction
	| SetRequestDetailToCompleteSuccessAction
	| SetRequestDetailToCompleteFailureAction
	| SetRequestDetailToCompleteResetAction;

/**
 * SET DOCUMENT TO COMPLETE
 */

export type SetDocumentToCompleteType = {
	readonly isSettingDocumentToComplete: boolean;
	readonly isSetDocumentToCompleteSuccess: boolean;
	readonly isSetDocumentToCompleteError: boolean;
};

export const setDocumentToCompleteDefaultValue: SetDocumentToCompleteType = {
	isSettingDocumentToComplete: false,
	isSetDocumentToCompleteSuccess: false,
	isSetDocumentToCompleteError: false,
};

export type SetDocumentToCompletePayloadType = {
	requestId: number;
	documentId: number;
};

type SetDocumentToCompleteAction = {
	type: typeof actionTypes.SET_DOCUMENT_TO_COMPLETE;
	payload: SetDocumentToCompletePayloadType;
};

type SetDocumentToCompleteSuccessAction = {
	type: typeof actionTypes.SET_DOCUMENT_TO_COMPLETE_SUCCESS;
};

type SetDocumentToCompleteFailureAction = {
	type: typeof actionTypes.SET_DOCUMENT_TO_COMPLETE_FAILURE;
};

type SetDocumentToCompleteResetAction = {
	type: typeof actionTypes.SET_DOCUMENT_TO_COMPLETE_RESET;
};

export type SetDocumentToCompleteActionTypes =
	| SetDocumentToCompleteAction
	| SetDocumentToCompleteSuccessAction
	| SetDocumentToCompleteFailureAction
	| SetDocumentToCompleteResetAction;

/**
 * REMOVE DOCUMENT
 */

export type RemoveDocumentType = {
	readonly fileKey: string;
	readonly isRemovingDocument: boolean;
	readonly isRemoveDocumentSuccess: boolean;
	readonly isRemoveDocumentError: boolean;
};

export const removeDocumentDefaultValue: RemoveDocumentType = {
	fileKey: "",
	isRemovingDocument: false,
	isRemoveDocumentSuccess: false,
	isRemoveDocumentError: false,
};

export type RemoveDocumentPayloadType = {
	fileKey: string;
};

type RemoveDocumentAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT;
	payload: RemoveDocumentPayloadType;
};

type RemoveDocumentSuccessAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_SUCCESS;
};

type RemoveDocumentFailureAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_FAILURE;
};

type RemoveDocumentResetAction = {
	type: typeof actionTypes.REMOVE_DOCUMENT_RESET;
};

export type RemoveDocumentActionTypes =
	| RemoveDocumentAction
	| RemoveDocumentSuccessAction
	| RemoveDocumentFailureAction
	| RemoveDocumentResetAction;
