import { call, put, select, takeLatest } from "redux-saga/effects";
import * as urls from "__utils/constants";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { requestDetailSlr } from "./selectors";
import { DocumentsType, GetRequestDetailType } from "./types";

// GET REQUESTS DETAILS

const getRequestDetails = function* (action: any): any {
	const { requestId } = action.payload;

	try {
		const response: ResponseGeneratorType = yield call(api.getRequestsDetailsAPI, { requestId });
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_REQUEST_DETAIL_SUCCESS,
				data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_REQUEST_DETAIL_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REQUEST_DETAIL_FAILURE });
	}
};

export const getRequestDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REQUEST_DETAIL, getRequestDetails);
};

// SET REQUESTS TO COMPLETE

const setRequestToComplete = function* (action: any): any {
	const { requestId } = action.payload;

	try {
		const response: ResponseGeneratorType = yield call(api.setRequestDetailToCompleteAPI, { requestId });
		const { status } = response;

		if (status === 204) {
			yield put({
				type: actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE_FAILURE });
	}
};

export const setRequestToCompleteSaga = function* (): any {
	yield takeLatest(actionTypes.SET_REQUEST_DETAIL_TO_COMPLETE, setRequestToComplete);
};

// SET DOCUMENT TO COMPLETE

const setDocumentToComplete = function* (action: any): any {
	const requestDetail: GetRequestDetailType = yield select(requestDetailSlr);
	const { payload } = action;

	try {
		const response: ResponseGeneratorType = yield call(api.setDocumentToCompleteAPI, payload);
		const { status } = response;

		if (status === 200) {
			const { data } = requestDetail;

			const updateCurrDocs = data.documents.map((docs: DocumentsType) => {
				if (docs.id === payload.documentId) {
					return { ...docs, isComplete: true };
				}
				return { ...docs };
			});

			// UPDATE THE REQUESTS DOCUMENT
			yield put({
				type: actionTypes.GET_REQUEST_DETAIL_SUCCESS,
				data: { ...data, documents: updateCurrDocs },
			});

			// UPDATE THE REQUESTS
			yield put({
				type: actionTypes.SET_DOCUMENT_TO_COMPLETE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.SET_DOCUMENT_TO_COMPLETE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.SET_DOCUMENT_TO_COMPLETE_FAILURE });
	}
};

export const setDocumentToCompleteSaga = function* (): any {
	yield takeLatest(actionTypes.SET_DOCUMENT_TO_COMPLETE, setDocumentToComplete);
};

// REMOVE DOCUMENT FILE

const removeDocFile = function* (action: any): any {
	const { payload } = action;

	try {
		const response: ResponseGeneratorType = yield call(api.removeDocumentAPI, payload);

		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.REMOVE_DOCUMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.REMOVE_DOCUMENT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.REMOVE_DOCUMENT_FAILURE });
	}
};

export const removeDocFileSaga = function* (): any {
	yield takeLatest(actionTypes.REMOVE_DOCUMENT, removeDocFile);
};
