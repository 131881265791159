import message from "antd/es/message";
import cx from "classnames";
import Parser from "html-react-parser";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import BellIcon from "__assets/svg/bell.svg";
import CloseIcon from "__assets/svg/close.svg";
import CongratsImg from "__assets/svg/congrats.svg";
import IdeaImg from "__assets/svg/dollar-idea.svg";
import SpeakImg from "__assets/svg/speak_directly.svg";
import AppButton from "__common/AppButton";
import SimpleLoader from "__common/SimpleLoader";
import { getProfileSlr } from "__pages/Account/__redux/selectors";
import { GetProfileType, ProfileType } from "__pages/Account/__redux/types";
import { layoutTypes, ObjectType } from "__utils/types";
import "./style.scss";
import { sendReferralCtr, sendReferralResetCtr } from "./__redux/actions";
import { notificationsSlr, referralSlr } from "./__redux/selectors";
import { GetNotificationType, NotificationType, SendReferralType } from "./__redux/types";

const validateEmail = (email: string): boolean => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

type Props = {
	LayoutProps: layoutTypes;
	onOpenCloseRightMenu: (e: boolean) => void;
	onCloseCongratsInfoBox: () => void;
};

const NotificationPanel: React.FC<Props> = ({ onOpenCloseRightMenu, onCloseCongratsInfoBox, LayoutProps }: Props) => {
	const { openCongratsInfo, openRightMenu }: layoutTypes = LayoutProps;
	const dispatch = useDispatch();
	const { isGettingNotification, notifications }: GetNotificationType = useSelector(notificationsSlr);
	const { congratulateMessage, newsItems }: NotificationType = notifications;
	const { data: profileData }: GetProfileType = useSelector(getProfileSlr);
	const { id: profileId, email: profileEmail }: ProfileType = profileData;
	const { isSendingRefferal, isSendReferralSuccess }: SendReferralType = useSelector(referralSlr);
	const [refferalEmail, setReferralEmail] = React.useState<string>("");

	React.useEffect(() => {
		if (isSendReferralSuccess) {
			message.success("Referral Sent");
			dispatch(sendReferralResetCtr());
			setReferralEmail("");
		}
	}, [isSendReferralSuccess, dispatch]);

	const onSendEmail = (): void => {
		// 	email,
		const details = {
			id: profileId,
			email: refferalEmail,
		};
		if (!validateEmail(refferalEmail) || !refferalEmail) {
			message.error("Please input a valid email");
		} else {
			dispatch(sendReferralCtr(details));
		}
	};

	const renderCongrats = (): React.ReactNode => {
		if (isEmpty(congratulateMessage)) return null;

		const { title, subTitle, description } = congratulateMessage;

		return (
			openCongratsInfo && (
				<div className="right-menu__top-container">
					<div className="right-menu__top-container__close-text" onClick={() => onCloseCongratsInfoBox()}>
						Close
					</div>
					<div className="right-menu__wrapper">
						<div className="right-menu__wrapper-left">
							<img src={CongratsImg} alt="Man on top podium" />
						</div>
						<div className="right-menu__wrapper-right">
							<p className="right-menu__header-text">{title}</p>
							<p className="right-menu__info-bold">{subTitle}</p>
							<div className="right-menu__info-text">{Parser(description)}</div>
						</div>
					</div>
				</div>
			)
		);
	};

	const renderNews = (): React.ReactNode => {
		if (isEmpty(newsItems)) return null;

		return (
			<>
				{newsItems.map((item: ObjectType) => {
					const { id } = item;
					const img = item.image || SpeakImg;
					const { title } = item;
					const { description } = item;

					return (
						<div className="right-menu__bottom-container" key={id}>
							<div className="right-menu__wrapper">
								<div className="right-menu__wrapper-left">
									<img src={img} alt="" />
								</div>
								<div className="right-menu__wrapper-right">
									<p className="right-menu__header-text">{title}</p>
									<div className="right-menu__info-text">{Parser(description)}</div>
								</div>
							</div>
						</div>
					);
				})}
			</>
		);
	};

	const renderReferral = (): React.ReactNode => {
		const title = "Refer a Friend";
		const description = "Receive 10% off your yearly membership fees for a successful referral.";
		if (profileEmail === "demo+1865@selfmade.com.au") return null;

		return (
			<div className="right-menu__referral-container">
				<div className="right-menu__wrapper">
					<div className="right-menu__wrapper-left">
						<img src={IdeaImg} alt="Documents" />
					</div>
					<div className="right-menu__wrapper-right">
						<p className="right-menu__header-text">{title}</p>
						<div className="right-menu__info-text">
							{Parser(description)}
							<Popup
								trigger={<span className="tooltip-link">More Info?</span>}
								position="bottom center"
								contentStyle={{ width: "300px", padding: "20px" }}
							>
								<div>
									<div className="popup-data">
										<p>
											Refer a friend to Selfmade, and if they on-board successfully, you are both
											eligible to receive 10% off your yearly membership fees for 12 months.
											Discount is applied from next calendar month following successful set up of
											fund.
										</p>
									</div>
								</div>
							</Popup>
						</div>

						<input
							className="refferal-email-input"
							type="email"
							placeholder="friend@example.com"
							value={refferalEmail}
							onChange={(e) => setReferralEmail(e.target.value)}
						/>

						<AppButton onClick={onSendEmail} isLoading={isSendingRefferal} className="email-submit-btn">
							Send Referral
						</AppButton>
					</div>
				</div>
			</div>
		);
	};

	const dynClass = cx("right-menu-container", { "right-menu-container--open": openRightMenu });

	return (
		<div className="right-menu-container-wrapper">
			<div className="right-menu-open-container" onClick={() => onOpenCloseRightMenu(true)}>
				<img src={BellIcon} className="right-menu-open-container__icon" alt="Left menu close icon" />
			</div>
			<div className={dynClass}>
				<div className="right-menu-wrapper">
					<div className="right-menu-container__close-container" onClick={() => onOpenCloseRightMenu(false)}>
						<img src={CloseIcon} className="right-menu-container__close-icon" alt="Left menu close icon" />
					</div>
					<div className="right-menu">
						<SimpleLoader num={2} show={isGettingNotification} darkMode />

						{/* referral link */}
						{renderReferral()}

						{/* congrats info box */}
						{renderCongrats()}

						{/* bottom info container */}
						{renderNews()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotificationPanel;
