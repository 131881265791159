import React from "react";
import EmptyData from "../EmptyData";
import "./style.scss";

// style import

type Props = unknown;

const PageNotFound: React.FC<Props> = () => {
	return (
		<div className="page-not-found-container">
			<EmptyData
				headerText="Page Not Found "
				pageMsg="404"
				notificationText="Sorry, the page you visited does not exist."
			/>
		</div>
	);
};

export default PageNotFound;
