import * as actionTypes from "./actionTypes";

/**
 * Get Profile
 */

export interface ProfileType {
	readonly firstName: string;
	readonly phone: string;
	readonly isNew: boolean;
	readonly email: string;
	readonly id: number;
}

export type GetProfileType = {
	readonly data: ProfileType;
	readonly isGettingProfile: boolean;
	readonly isGetProfileSuccess: boolean;
	readonly isGetProfileError: boolean;
};

export const getProfileDefaultValue: GetProfileType = {
	data: {
		firstName: "",
		phone: "",
		isNew: false,
		email: "",
		id: 0,
	},
	isGettingProfile: false,
	isGetProfileSuccess: false,
	isGetProfileError: false,
};

type GetProfileAction = {
	type: typeof actionTypes.GET_PROFILE;
};

type GetProfileSuccessAction = {
	type: typeof actionTypes.GET_PROFILE_SUCCESS;
};

type GetProfileFailureAction = {
	type: typeof actionTypes.GET_PROFILE_FAILURE;
};

type GetProfileResetAction = {
	type: typeof actionTypes.GET_PROFILE_RESET;
};

export type GetProfileActionTypes =
	| GetProfileAction
	| GetProfileSuccessAction
	| GetProfileFailureAction
	| GetProfileResetAction;

/**
 * Edit Profile
 */
export type EditProfileType = {
	readonly isEditingProfile: boolean;
	readonly isEditProfileSuccess: boolean;
	readonly isEditProfileError: boolean;
};

export const EditProfileDefaultValue: EditProfileType = {
	isEditingProfile: false,
	isEditProfileSuccess: false,
	isEditProfileError: false,
};

export type EditProfilePayloadType = {
	firstName: string;
	phone: string;
	isNew: boolean;
	email: string;
	id: number;
	password1?: string;
	password2?: string;
};

type EditProfileAction = {
	type: typeof actionTypes.EDIT_PROFILE;
	payload: EditProfilePayloadType;
};

type EditProfileSuccessAction = {
	type: typeof actionTypes.EDIT_PROFILE_SUCCESS;
};

type EditProfileFailureAction = {
	type: typeof actionTypes.EDIT_PROFILE_FAILURE;
};

type EditProfileResetAction = {
	type: typeof actionTypes.EDIT_PROFILE_RESET;
};

export type EditProfileActionTypes =
	| EditProfileAction
	| EditProfileSuccessAction
	| EditProfileFailureAction
	| EditProfileResetAction;

/**
 *
 */
export type IVerifyAuth = {
	readonly verifyingAuth: boolean;
	readonly verifyAuthSuccess: boolean;
	readonly verifyAuthFailure: boolean;
};

export const verifyAuthDefaultValue = {
	verifyingAuth: false,
	verifyAuthSuccess: false,
	verifyAuthFailure: false,
};

export type IVerifyAuthPayload = {
	readonly referer: string;
};

type IVerifyAuthAction = {
	type: typeof actionTypes.VERIFY_AUTH;
	payload: IVerifyAuthPayload;
};

type IVerifyAuthSuccessAction = {
	type: typeof actionTypes.VERIFY_AUTH_SUCCESS;
};

type IVerifyAuthFailureAction = {
	type: typeof actionTypes.VERIFY_AUTH_FAILURE;
};

export type IVerifyAuthActions = IVerifyAuthAction | IVerifyAuthSuccessAction | IVerifyAuthFailureAction;
