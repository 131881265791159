import { endpoints } from "__utils/constants";
import { MenuItemsType } from "__utils/types";

const menuItems: Array<MenuItemsType> = [
	{
		menuText: "ABOUT",
		isExternal: true,
		openInNewTab: true,
		route: endpoints.websiteAboutURL,
	},
	{
		menuText: "FAQS",
		isExternal: true,
		openInNewTab: true,
		route: endpoints.websiteFAQsURL,
	},
	{
		menuText: "HOW IT WORKS",
		isExternal: true,
		openInNewTab: true,
		route: endpoints.websiteHowItWorksURL,
	},
	{
		menuText: "MY ACCOUNT",
		isExternal: false,
		route: "/account",
	},
	{
		menuText: "SIGN OUT",
		isExternal: true,
		route: endpoints.websiteSignOutURL,
	},
	{
		menuText: "DASHBOARD",
		isExternal: false,
		openInNewTab: false,
		route: "/",
	},
];

export default menuItems;
