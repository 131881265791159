import * as actionTypes from "./actionTypes";
import { GetSigningDetailsActionTypes, getSigningDetailsDefaultValue, GetSigningDetailsType } from "./types";

export const signingDetailsReducer = (
	state = getSigningDetailsDefaultValue,
	action: GetSigningDetailsActionTypes
): GetSigningDetailsType => {
	switch (action.type) {
		case actionTypes.GET_SIGNING_DETAILS:
			return {
				...state,
				isGettingSigningDetails: true,
			};
		case actionTypes.GET_SIGNING_DETAILS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingSigningDetails: false,
				isGetSigningDetailsSuccess: true,
				isGetSigningDetailsError: false,
			};

		case actionTypes.GET_SIGNING_DETAILS_FAILURE:
			return {
				...state,
				isGettingSigningDetails: false,
				isGetSigningDetailsSuccess: false,
				isGetSigningDetailsError: true,
			};

		case actionTypes.GET_SIGNING_DETAILS_RESET:
			return {
				...getSigningDetailsDefaultValue,
			};
		default:
			return state;
	}
};
