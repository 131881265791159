import { combineReducers } from "redux";
import { signingDetailsReducer } from "../../SigningRequests/__redux/reducers";
import { requestsDetailsReducer } from "../DocumentRequestDetail/__redux/reducers";
import * as actionTypes from "./actionTypes";
import { GetRequestsActionTypes, getRequestsDefaultValue, GetRequestsType } from "./types";

export const requestsStateReducer = (
	state = getRequestsDefaultValue,
	action: GetRequestsActionTypes
): GetRequestsType => {
	switch (action.type) {
		case actionTypes.GET_REQUESTS:
			return {
				...state,
				isGettingRequests: true,
			};
		case actionTypes.GET_REQUESTS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingRequests: false,
				isGetRequestsSuccess: true,
				isGetRequestsError: false,
			};

		case actionTypes.GET_REQUESTS_FAILURE:
			return {
				...state,
				isGettingRequests: false,
				isGetRequestsSuccess: false,
				isGetRequestsError: true,
			};

		case actionTypes.GET_REQUESTS_RESET:
			return {
				...getRequestsDefaultValue,
			};
		default:
			return state;
	}
};

export const requestsReducer = combineReducers({
	requestsState: requestsStateReducer,
	requestsDetails: requestsDetailsReducer,
	signingDetails: signingDetailsReducer,
});
