import React from "react";
import { TourType } from "__utils/types";

export const tourState: TourType = {
	continuous: true,
	loading: false,
	run: false,
	spotlightPadding: 0,
	styles: {
		options: {
			primaryColor: "#31259F",
			textColor: "#000",
			overlayColor: "rgba(0, 0, 0, 0.65)",
		},
		buttonSkip: {
			fontSize: 14,
		},
		buttonClose: {
			display: "none",
		},
		tooltip: {
			padding: 25,
			borderRadius: 0,
		},
		tooltipTitle: {
			margin: "0",
		},
		tooltipContent: {
			padding: "10px 0px",
			fontSize: 14,
			textAlign: "left",
		},
		tooltipFooter: {
			marginTop: 10,
		},
		buttonNext: {
			padding: "8px 16px",
			fontSize: 14,
			fontWeight: "bold",
			lineHeight: "21px",
		},
		buttonBack: {
			fontSize: 14,
		},
	},
	steps: [
		{
			title: <div className="tour__main-title">Welcome to Selfmade!</div>,
			content: (
				<div>
					<div className="tour__main-desc">
						Congratulations, you have completed the account establishment process for your new SMSF. There
						are a few more important steps you need to complete before your account is fully activated and
						you are able to start investing.
					</div>
					<p className="tour__main-point">
						<span className="tour__main-point__highlight">Sign establishment documents</span> - You and any
						other trustees/members need to sign the establishment documents via Docusign
					</p>
					<p className="tour__main-point">
						<span className="tour__main-point__highlight">Activate bank accounts</span> - your SMSF’s new
						Cash Management and Online Trading Accounts have been set up with Macquarie but need to be
						activated before you can start investing
					</p>
					<p className="tour__main-point">
						<span className="tour__main-point__highlight">Set up contributions</span> - Set up future
						employer contributions to be paid into your new SMSF
					</p>
					<p className="tour__main-point">
						<span className="tour__main-point__highlight">Roll over</span> – Roll your existing super into
						your new SMSF
					</p>
				</div>
			),
			placement: "center",
			disableBeacon: true,
			styles: {
				options: {
					width: 750,
					zIndex: 10000,
				},
			},
			locale: { skip: "Skip", next: "Take a Quick Tour" },
			target: "body",
		},
		// {
		//     title: <div className="tour__main-title">Pending Actions</div>,
		//     content: <div>This is where we will keep you updated with any actions required by you to keep your SMSF up
		//                     to date and compliant.</div>,
		//     placement: "left",
		//     disableBeacon: true,
		//     styles: {
		//         options: {
		//             width: 500
		//         }
		//     },
		//     target: ".right-menu__middle-container",
		//     spotlightPadding: 3,
		// },
		{
			title: <div className="tour__main-title">Refer a Friend</div>,
			content: (
				<div>
					Refer a friend to Selfmade, and if they sign-up, you are both eligible to receive 15% off your
					yearly membership fees for 12 months. Discount is applied from next calendar month following
					successful setup.
				</div>
			),
			placement: "left",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".right-menu__referral-container",

			spotlightPadding: 3,
		},
		{
			title: <div className="tour__main-title">Notifications Panel</div>,
			content: (
				<div>
					You can minimise the notifications panel here. If you have any outstanding actions that need your
					attention, the panel will be open when you next log in.
				</div>
			),
			placement: "left",
			disableBeacon: true,
			styles: {
				options: {
					width: 515,
				},
			},
			target: ".right-menu-container__close-container",
			spotlightPadding: 3,
		},
		{
			title: <div className="tour__main-title">Workflow</div>,
			content: (
				<div>
					The workflow section shows you at what stage your fund is up to in any given workflow, such as
					on-boarding or end of financial year tax lodgements.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".workflow-card-header span",
		},
		{
			title: <div className="tour__main-title">Dashboard</div>,
			content: (
				<div>
					The dashboard gives you a snapshot of your SMSF including your fund&#x27;s current investment
					holdings, the fund value, a list of recent transactions as well as the workflow overview.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".dashboard_target",
		},
		{
			title: <div className="tour__main-title">Requests</div>,
			content: <div>You can review your outstanding document and signing requests here.</div>,
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".requests_target",
		},
		{
			title: <div className="tour__main-title">My Fund</div>,
			content: (
				<div>
					This section displays all the important details of your SMSF including details of trustees and
					members, registered addresses, the fund name and any other registration details.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".myfund_target",
		},
		{
			title: <div className="tour__main-title">Trading</div>,
			content: (
				<div>
					This button will send you to the Macquarie login page to access your SMSF’s new transaction and
					online trading accounts. You will have received separate emails from Macquarie with login details
					for the Macquarie site. It is important that you complete the Macquarie activation steps ASAP so
					that you are able to roll over your existing super and receive new contributions.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".trading_target",
		},
		{
			title: <div className="tour__main-title">Documents</div>,
			content: (
				<div>
					You can access your SMSF&#x27;s documents here at any time - including any uploaded and signed
					documents provided via requests.
				</div>
			),
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".documents_target",
		},
		{
			title: <div className="tour__main-title">Billing</div>,
			content: <div>You can review your fund&#x27;s invoices here at any time.</div>,
			placement: "right",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
				},
			},
			target: ".billing_target",
		},
		{
			title: <div className="tour__main-title">Let&#x27;s get started</div>,
			content: (
				<div className="tour__main-footer">
					You’re all set, now it&#x27;s time to complete the activation steps and take control of your super.
				</div>
			),
			placement: "center",
			disableBeacon: true,
			styles: {
				options: {
					width: 500,
					zIndex: 10000,
				},
			},
			locale: { back: "", skip: "Skip", last: "Go to the Dashboard" },
			target: "body",
		},
	],
	stepIndex: 0,
};
