import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { BASIC_FETCH_FUND, FETCH_FUND } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getFundAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(FETCH_FUND).catch(errorHandler);
};

export const getBasicFundAPI = async (): Promise<AxiosResponse<any>> => {
	return axios.get(BASIC_FETCH_FUND).catch(errorHandler);
};
